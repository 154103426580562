<template>
  <q-layout view="hhh lpr fff" class="bg-primary">
    <q-header class="bg-primary text-white" v-show="hideHeader">
      <div class="row full-width justify-center bg-primary">
        <div class="logo">
          <a href="https://www.pitchyou.de" target="_blank"
            ><img src="../assets/pitchyou-logo.png" class="img-fluid"
          /></a>
        </div>
      </div>
    </q-header>
    <q-page-container>
      <q-page class="bg-primary flex justify-center items-center">
        <router-view />
      </q-page>
    </q-page-container>
    <SmallFooter />
  </q-layout>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SmallFooter from '@/components/SmallFooter.vue';
import { TenantLoginInfo } from '@/store/models';
import tenantModule from '@/store/modules/tenantModule';

//import { useI18n } from 'vue-i18n';

// const { t, locale } = useI18n();

const tenantLoginInfo = ref<TenantLoginInfo | null>(null);
const hideHeader = ref(false);



onMounted(async () => {
  await loadTenantLoginInfo();
  if (tenantLoginInfo.value) {
    hideHeader.value = !tenantLoginInfo.value.hideLogin;
  }
});

const loadTenantLoginInfo = async () => {
  tenantLoginInfo.value = tenantModule.tenantLoginInfo || null;
  if (!tenantLoginInfo.value) {
    await tenantModule.loadTenantLoginInfo();
    tenantLoginInfo.value = tenantModule.tenantLoginInfo;
  }
};

</script>

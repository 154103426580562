<template>
  <q-layout view="hhh lpr fff" class="shadow-3">
    <q-header elevated class="bg-primary text-white">
      <div class="hf-container flex column justify-between">
        <div class="q-py-xs flex justify-around items-center">
          <q-img
            :src="tenantLite.logo"
            class="logoImage"
            contain
            v-if="tenantLite && tenantLite.logo && '' != tenantLite.logo"
          /><TenantInfo :tenantLite="tenantLite" v-else />
        </div>
      </div>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
    <BrandFooter :showTermsOfUse="true" />
  </q-layout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import TenantInfo from '@/components/TenantInfo.vue';
import BrandFooter from '@/components/BrandFooter.vue';
import { TenantLite } from '../store/models';
import tenantModule from '@/store/modules/tenantModule';


import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { colors, setCssVar, useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();



const name = ref('DefaultLayout');
const tenantLite = ref<TenantLite | null>(null);

  onMounted(async () => {
    loadTenantInfoLite();
  });

 async function loadTenantInfoLite() {
  try {
        tenantLite.value = tenantModule.tenantInfoLite || null;
        if (!tenantLite.value) {
          await tenantModule.loadTenantInfoLite();
          tenantLite.value = tenantModule.tenantInfoLite;
          if (tenantLite.value && tenantLite.value.primaryColor) {
            setCssVar('primary', tenantLite.value.primaryColor);
          }
          if (tenantLite.value && tenantLite.value.secondaryColor) {
            setCssVar('secondary', tenantLite.value.secondaryColor);
          }
        }
      } catch (error) {
        console.error(error);
      }
      if (!tenantLite.value) {
        router.push({ name: 'invalidtenant' });
      }
  }

</script>

<style></style>

import { createStore } from 'vuex';
import axios from 'axios';
import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';;
import { createRouter, createWebHistory } from 'vue-router';

axios.interceptors.request.use(request => {
  if (SessionStorage.getItem('app-token')) {
    request.headers['Authorization'] =
      'Bearer ' + SessionStorage.getItem('app-token') || '';
  }
  if (SessionStorage.getItem('pin') && SessionStorage.getItem('shareGuid')) {
    request.headers['PinAuth'] =
      'Bearer ' +
      JSON.stringify({
        Pin: SessionStorage.getItem('pin'),
        ShareGuid: SessionStorage.getItem('shareGuid')
      });
  }
  return request;
});

export default createStore({
  state: {},
  mutations: {},
  actions: {}
});
<template>
  <q-layout view="hhh lpR fff" class="shadow-3">
    <q-header elevated class="primary text-white">
      <q-toolbar>
        <q-toolbar-title>
          <div class="row justify-around items-center content-center q-py-xs">
            <q-img
              :src="tenantLite.logo"
              class="logoImage"
              contain
              v-if="tenantLite && tenantLite.logo && '' != tenantLite.logo"
            />
            <TenantInfo :tenantLite="tenantLite" v-else />
          </div>
        </q-toolbar-title>
        <q-btn
          dense
          flat
          round
          icon="menu"
          @click="rightDrawerOpen = !rightDrawerOpen"
        />
      </q-toolbar>
    </q-header>
    <q-drawer v-model="rightDrawerOpen" side="right" elevated behavior="mobile">
      <BrandDrawer :showTermsOfUse="true" />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import TenantInfo from '@/components/TenantInfo.vue';
import BrandDrawer from '@/components/BrandDrawer.vue';
import { TenantLite } from '../store/models';
import tenantModule from '@/store/modules/tenantModule';
import { colors, setCssVar, useQuasar } from 'quasar';

import { defineComponent, ref,onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();


const name = ref<string>('CompactLayout');
const tenantLite = ref<TenantLite | null>(null);
const rightDrawerOpen = ref<boolean>(false);

  onMounted(async () => {
    loadTenantInfoLite();
  });

 async function loadTenantInfoLite() {
    try {
      tenantLite.value = tenantModule.tenantInfoLite || null;
      if (!tenantLite.value) {
        await tenantModule.loadTenantInfoLite();
        tenantLite.value = tenantModule.tenantInfoLite;
        if (tenantLite.value && tenantLite.value.primaryColor) {
          setCssVar('primary', tenantLite.value.primaryColor)
        }
        if (tenantLite.value && tenantLite.value.secondaryColor) {
          setCssVar('secondary', tenantLite.value.secondaryColor)
        } else {
          setCssVar('secondary', '#6ab0ea')
        }

        if (tenantLite.value && tenantLite.value.primaryTextColor) {
          setCssVar(
            'default-sent-chat-text-color',
            tenantLite.value.primaryTextColor
          );

        } else {
          setCssVar('default-sent-chat-text-color', '#fff');
        }

        if (tenantLite.value && tenantLite.value.secondaryTextColor) {
          setCssVar(
            'default-receive-chat-text-color',
            tenantLite.value.secondaryTextColor
          );
        } else {
          setCssVar('default-receive-chat-text-color', '#000');
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (!tenantLite.value) {
      router.push({ name: 'invalidtenant' });
    }
  }

</script>

<style></style>

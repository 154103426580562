<template>
  <q-footer elevated class="bg-secondary text-white q-pt-md q-mt-lg">
    <div class="row justify-center full-width">
      <div class="logo">
        <a href="https://www.pitchyou.de" target="_blank"
          ><img src="../assets/pitchyou-logo.png" class="img-fluid"
        /></a>
      </div>
    </div>

    <div class="row full-width justify-center items-center">
      <div>
        <a
          class="text-white q-pa-sm"
          href="https://www.pitchyou.de/impressum"
          target="_blank"
          >{{ $t('message.legalNoticeFLbl') }}</a
        ><a
          class="text-white q-pa-sm"
          href="https://www.pitchyou.de/datenschutz"
          target="_blank"
          >{{ $t('message.privacyPolicyFLbl') }}</a
        ><a
          v-show="showTermsOfUse"
          class="text-white q-pa-sm"
          href="https://www.pitchyou.de/nutzungsbedingungen"
          target="_blank"
          >{{ $t('message.termsOfUseFLbl') }}</a
        >
      </div>
      <div class="copyright text-center q-pa-md q-mt-xs bg-secondary">
        &copy; {{ currentYear() }} PitchYou GmbH
      </div>
    </div>
  </q-footer>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const name = ref<string>('BrandFooter');
const props = defineProps(['showTermsOfUse']);
const showTermsOfUse = ref<boolean | undefined>(props.showTermsOfUse);
function currentYear() {
  return new Date().getFullYear();
}
</script>

<style></style>

import {
  RecruiterInterview,
  DashboardInterviewsRequest,
  DashboardInterviewsResponse,
  LoginRequest,
  User,
  PrivacyConfirmationRequest,
  PrivacyConfirmationResponse,
  CommentResponse,
  Comment,
  CommentRequest,
  JobListRequest,
  JobListResponse,
  JobDashboardRequest,
  JobDashboardResponse,
  JobDashboardSearchRequest,
  JobDashboardSearchResponse,
  JobDashboardStatusChangeRequest,
  JobDashboardStatusChangeResponse,
  TransitionStatusRequest,
  JobItem,
  JobDashboardInterview,
  JobClusterItem,
  SaveJobClusterRequest,
  UpdateUserStatusRequest,
  LoadInterviewDetailRequest
} from '@/store/models';
import {
  fetchInterviewRecruiterDetailServer,
  fetchJobListServer,
  doJobDashboardSearchServer,
  fetchJobDashboardDetailsServer
} from '@/store/server/api-server';
import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';import date from 'quasar/src/utils/date/date.js';;
import { orderBy, slice, find, findIndex } from 'lodash-es';

export const mockUser: User = {
  firstName: 'Recruiter',
  lastName: 'Mustermann',
  slug: '1',
  username: 'demo',
  roleLevel: 'RECRUITER_READ',
  active: true,
  userAdminRole: 'NONE',
  notifyNewInterviews: false,
  notifyDailySummary: false,
  notifyUnreadChatMessages: false,
  allJobsAndSitesuser: false,
  tfaRequired: false,
  tfaEnabled: false
};
let mockComments: Comment[] = [];
const mockTimeout = 10;

async function getJobDashboardInterviews(
  requestObj: JobDashboardSearchRequest
): Promise<JobDashboardInterview[]> {
  return new Promise(async (resolve, reject) => {
    try {
      const jobDashboardInterviews: JobDashboardSearchResponse[] =
        SessionStorage.getItem('app-job-dashboard-interviews') || [];

      let jobInterviewsIndex = findIndex(jobDashboardInterviews, function(t) {
        return t.request.jobSlug === requestObj.jobSlug;
      });
      if (jobInterviewsIndex < 0) {
        jobDashboardInterviews.push(
          await doJobDashboardSearchServer(requestObj)
        );
        jobInterviewsIndex = findIndex(jobDashboardInterviews, function(t) {
          return t.request.jobSlug === requestObj.jobSlug;
        });
        await setToSessionStorage(
          'app-job-dashboard-interviews',
          jobDashboardInterviews
        );
      }

      resolve(jobDashboardInterviews[jobInterviewsIndex].interviews);
    } catch (err) {
      console.log(err);
      reject(new Error('Error finding job interviews'));
    }
  });
}

async function getOrLoadExistingJobDashboardInterview(
  interviewGuid: string,
  jobSlug: string
): Promise<JobDashboardInterview> {
  return new Promise(async (resolve, reject) => {
    try {
      const jobDashboardInterviews: JobDashboardSearchResponse[] =
        SessionStorage.getItem('app-job-dashboard-interviews') || [];
      let jobInterview = null;
      jobDashboardInterviews.forEach(element => {
        jobInterview = find(element.interviews, function(t) {
          return t.slug === interviewGuid;
        });
      });
      if (!jobInterview) {
        const jobDashboardRequest: JobDashboardSearchRequest = {
          jobSlug: jobSlug,
          pageSize: 10,
          currentPage: 1,
          siteSlugs: [],
          criteria: 'top-new',
          filter: {},
          sort: {
            sortBy: 'match',
            sortDirection: 'DESC'
          },
          filterPostingJobs: false
        };
        await getJobDashboardInterviews(jobDashboardRequest);
        resolve(getOrLoadExistingJobDashboardInterview(interviewGuid, jobSlug));
        return;
      }

      resolve(jobInterview || undefined);
      return;
    } catch (err) {
      console.log(err);
      reject('Error finding job interview');
    }
  });
}

async function setToSessionStorage(key: string, value: object): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    try {
      resolve(SessionStorage.set(key, value));
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

function chunk(arr: any, chunkSize: number) {
  const results = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    results.push(arr.slice(i, i + chunkSize));
  }
  return results;
}

export async function performLoginMock(
  loginRequest: LoginRequest
): Promise<User> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(mockUser);
    }, mockTimeout);
  });
}

export async function submitPrivacyConfirmationMock(
  confirmRequest: PrivacyConfirmationRequest
): Promise<PrivacyConfirmationResponse> {
  return new Promise(resolve => {
    setTimeout(() => {
      Promise.reject(new Error('PRIVACY_CALL_FAILED'));
    }, mockTimeout);
  });
}

export async function fetchInterviewRecruiterCommentsMock(
  interviewGuid: string
): Promise<CommentResponse> {
  return new Promise(resolve => {
    setTimeout(() => {
      const response: CommentResponse = { comments: mockComments };
      resolve(response);
    }, mockTimeout);
  });
}

export async function saveInterviewRecruiterCommentMock(
  commentRequest: CommentRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const tNow = (
        Date.now ||
        function() {
          return +new Date();
        }
      )();
      const rSlug: string = tNow.toString();
      const comment: Comment = {
        byFirstname: mockUser.firstName,
        byLastname: mockUser.lastName,
        text: commentRequest.text,
        slug: rSlug,
        createdAt: date.formatDate(tNow, 'D.M.YYYY H:m')
      };
      mockComments.push(comment);
      resolve(comment);
    }, mockTimeout);
  });
}

export async function fetchJobListMock(
  jobListRequest: JobListRequest
): Promise<JobListResponse> {
  if (!SessionStorage.getItem('app-joblist')) {
    setToSessionStorage(
      'app-joblist',
      await fetchJobListServer(jobListRequest)
    );
  }

  const jobList = SessionStorage.getItem('app-joblist') as JobListResponse;

  return new Promise((resolve, reject) => {
    const response: JobListResponse = {
      jobItems: [],
      totalResults: 0,
      request: jobListRequest,
      renderNewJob: false,
      activeJobs: 0,
      inactiveJobs: 0,
      testingJobs: 0
    };
    try {
      if (jobList) {
        const input: JobItem[] = [...jobList.jobItems];
        const result: JobItem[] = input.filter((job: JobItem) => {
          let match = true;
          if (
            jobListRequest.filter &&
            jobListRequest.filter.activeStatus != null
          ) {
            if (job.activeStatus !== jobListRequest.filter.activeStatus) {
              match = false;
            }
          }
          if (jobListRequest.filter && jobListRequest.filter.title != null) {
            if (
              jobListRequest.filter.title != '' &&
              job.title &&
              job.title
                .toLowerCase()
                .indexOf(jobListRequest.filter.title.toLowerCase()) == -1
            ) {
              match = false;
            }
          }
          return match;
        });
        response.jobItems = result;
        response.totalResults = response.jobItems.length || 0;
      }
    } catch (err) {
      console.log(err);
    }
    resolve(response);
  });
}

function getInterviewCount(dInterviews: [], status: string): number {
  if (dInterviews && dInterviews.length) {
    const filtered = dInterviews.filter((dInterview: any) => {
      return dInterview.status == status;
    });
    if (filtered && filtered.length) {
      return filtered.length;
    }
  }
  return 0;
}

export async function fetchJobDashboardDetailsMock(
  requestObj: JobDashboardRequest
): Promise<JobDashboardResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const jobInterviews: any =
        SessionStorage.getItem('app-job-dashboard-interviews') || null;

      let jobIndex = -1;

      if (jobInterviews) {
        jobIndex = findIndex(jobInterviews, function(
          t: JobDashboardSearchResponse
        ) {
          return t.request.jobSlug === requestObj.jobSlug;
        });
      }

      let dashboardDetails: JobDashboardResponse;
      const allDashboardDetails: JobDashboardResponse[] =
        SessionStorage.getItem('app-job-dashboard-details') || [];
      const detailsIndex = findIndex(allDashboardDetails, function(
        t: JobDashboardResponse
      ) {
        return t.slug === requestObj.jobSlug;
      });

      if (detailsIndex < 0) {
        dashboardDetails = await fetchJobDashboardDetailsServer(requestObj);
        allDashboardDetails.push(dashboardDetails);
        setToSessionStorage('app-job-dashboard-details', allDashboardDetails);
      } else {
        dashboardDetails = allDashboardDetails[detailsIndex];
      }

      if (jobIndex === -1) {
        resolve(dashboardDetails);
      }

      const interviewsOfjob: [] = jobInterviews[jobIndex].interviews || [];
      let topNewCount = 0,
        totalNewCount = 0,
        notInterestingCount = 0,
        interestingCount = 0,
        doneCount = 0,
        // eslint-disable-next-line prefer-const
        parked = 0,
        discardCount = 0;
      if (interviewsOfjob.length) {
        totalNewCount = getInterviewCount(interviewsOfjob, 'INITIAL');
        if (totalNewCount > 0) {
          topNewCount = Math.min(totalNewCount, 10);
        }
        notInterestingCount = getInterviewCount(
          interviewsOfjob,
          'NOT_INTERESTING'
        );
        interestingCount = getInterviewCount(interviewsOfjob, 'INTERESTING');
        discardCount = getInterviewCount(interviewsOfjob, 'DISCARDED');
        doneCount = getInterviewCount(interviewsOfjob, 'DONE');
      }
      const response: JobDashboardResponse = {
        slug: dashboardDetails.slug,
        title: dashboardDetails.title,
        sites: dashboardDetails.sites,
        renderWizardBtn: false,
        applicantCount: {
          topNew: topNewCount,
          parked: parked,
          notInteresting: notInterestingCount,
          interesting: interestingCount,
          done: doneCount,
          discarded: discardCount,
          jobInterview: 0,
          total:
            totalNewCount + notInterestingCount + interestingCount + doneCount
        }
      };
      resolve(response);
    } catch (err) {
      reject(new Error('JOB_DASHBOARD_SEARCH_FAILED'));
    }
  });
}

function getStatusFromCriteria(tab: string): string {
  let status = '';
  if (tab) {
    if (tab === 'top-new') {
      status = 'INITIAL';
    } else if (tab === 'not-interesting') {
      status = 'NOT_INTERESTING';
    } else if (tab === 'interesting') {
      status = 'INTERESTING';
    } else if (tab === 'discarded') {
      status = 'DISCARDED';
    } else if (tab === 'done') {
      status = 'DONE';
    }
  }
  return status;
}

function getCriteriaFromStatus(status: string): string {
  let criteria = 'top-new';
  if (status) {
    if (status === 'NOT_INTERESTING') {
      criteria = 'not-interesting';
    } else if (status === 'INTERESTING') {
      criteria = 'interesting';
    } else if (status === 'DISCARDED') {
      criteria = 'discarded';
    } else if (status === 'DONE') {
      criteria = 'done';
    }
  }
  return criteria;
}

export async function doJobDashboardSearchMock(
  requestObj: JobDashboardSearchRequest
): Promise<JobDashboardSearchResponse> {
  return new Promise(async (resolve, reject) => {
    const response: JobDashboardSearchResponse = {
      totalResults: 0,
      request: requestObj,
      interviews: [] as any
    };
    try {
      const status = getStatusFromCriteria(requestObj.criteria);
      const dashboardInterviews: any = await getJobDashboardInterviews(
        requestObj
      );
      const filteredInterviews =
        dashboardInterviews.filter((dInterview: any) => {
          let match = true;
          if (status !== '' && dInterview.status !== status) {
            match = false;
          }
          if (match && requestObj.filter) {
            if (
              requestObj.filter.applicantName &&
              requestObj.filter.applicantName != '' &&
              dInterview.candidate.name
                .toLowerCase()
                .indexOf(requestObj.filter.applicantName.toLowerCase()) == -1
            ) {
              match = false;
            }
            if (
              requestObj.filter.matchPercent &&
              requestObj.filter.matchPercent != ''
            ) {
              try {
                if (
                  dInterview.matchPercent <
                  parseInt(requestObj.filter.matchPercent)
                ) {
                  match = false;
                }
              } catch (err) {
                match = false;
              }
            }
          }
          return match;
        }) || [];
      response.interviews = [
        ...filteredInterviews.map((dInterview: any) => ({
          ...dInterview,
          draggable: status !== 'DISCARDED',
          relevantTab: getCriteriaFromStatus(dInterview.status)
        }))
      ];
      let sortBy = 'matchPercent';
      let sortDirection = 'DESC';
      if (requestObj.sort) {
        if (requestObj.sort.sortBy) {
          if (requestObj.sort.sortBy === 'applicant-name') {
            sortBy = 'candidate.name';
          } else if (requestObj.sort.sortBy === 'applied-since') {
            sortBy = 'createdAt';
          } else if (requestObj.sort.sortBy === 'no-of-comments') {
            sortBy = 'noOfComments';
          } else if (requestObj.sort.sortBy === 'status') {
            sortBy = 'status';
          }
        }
        if (requestObj.sort.sortDirection) {
          sortDirection = requestObj.sort.sortDirection;
        }
      }
      response.interviews = orderBy(
        response.interviews,
        [sortBy],
        [sortDirection === 'DESC' ? 'desc' : 'asc']
      );
      if (
        requestObj.criteria === 'top-new' &&
        response.interviews.length &&
        response.interviews.length > 10
      ) {
        response.interviews = slice(response.interviews, 0, 10);
      }
      response.totalResults = response.interviews.length;
    } catch (err) {
      console.log(err);
    }
    resolve(response);
  });
}

export async function doJobDashboardStatusChangeMock(
  requestObj: JobDashboardStatusChangeRequest
): Promise<JobDashboardStatusChangeResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const cStatus = getStatusFromCriteria(requestObj.sourceTab);
      const tStatus = getStatusFromCriteria(requestObj.targetTab);

      const jobDashboardInterviews: JobDashboardSearchResponse[] =
        SessionStorage.getItem('app-job-dashboard-interviews') || [];

      jobDashboardInterviews.forEach(element => {
        element.interviews.forEach(interview => {
          if (interview.slug === requestObj.interviewGuid) {
            if (interview.status === cStatus && tStatus !== cStatus) {
              interview.status = tStatus;
            }
          }
        });
      });

      await setToSessionStorage(
        'app-job-dashboard-interviews',
        jobDashboardInterviews
      );
      const response: JobDashboardStatusChangeResponse = { result: 'OK' };
      resolve(response);
    } catch (err) {
      console.log(err);
      reject(new Error('JOB_DASHBOARD_STATUS_CHANGE_FAILED'));
    }
  });
}

async function getInterviewRecruiterDetail(
  rInterview: RecruiterInterview,
  dInterview: any
): Promise<RecruiterInterview> {
  return new Promise<RecruiterInterview>((resolve, reject) => {
    const response: RecruiterInterview = {
      ...rInterview,
      renderUnlocked: true,
      matchPercent: dInterview.matchPercent,
      disableDone: dInterview.status === 'DONE',
      disableNotInteresting: dInterview.status === 'NOT_INTERESTING',
      disableInteresting: dInterview.status === 'INTERESTING',
      disableUnlocked: dInterview.unlocked
    };
    response.candidate.name = dInterview.candidate.name;
    if (dInterview.candidate.mobile) {
      response.candidate.mobile = dInterview.candidate.mobile;
    }
    if (dInterview.candidate.email) {
      response.candidate.email = dInterview.candidate.email;
    }
    if (!response.disableUnlocked) {
      if (response.candidate.mobile) {
        response.candidate.mobile = undefined;
      }
      if (response.candidate.email) {
        response.candidate.email = undefined;
      }
    }
    response.renderShareProfile = dInterview.unlocked;
    response.status = dInterview.status;
    resolve(response);
  });
}

export async function fetchInterviewRecruiterDetailMock(
  interviewGuid: string,
  jobSlug: string
): Promise<RecruiterInterview> {
  return new Promise<RecruiterInterview>(async (resolve, reject) => {
    try {
      const request: LoadInterviewDetailRequest = {
        interviewGuid: interviewGuid,
        jobSlug: ''
      };
      const rInterview: RecruiterInterview = await fetchInterviewRecruiterDetailServer(
        request
      );
      mockComments = [];
      const dInterview = await getOrLoadExistingJobDashboardInterview(
        interviewGuid,
        jobSlug
      );
      if (!dInterview) {
        resolve(rInterview);
        return;
      }
      const response: RecruiterInterview = await getInterviewRecruiterDetail(
        rInterview,
        dInterview
      );
      resolve(response);
    } catch (err) {
      console.log(err);
      reject(new Error('INTERVIEW_STATUS_TRANSITION_FAILED'));
    }
  });
}

export async function doStatusTransitionMock(
  requestObj: TransitionStatusRequest
): Promise<RecruiterInterview> {
  try {
    const request: LoadInterviewDetailRequest = {
      interviewGuid: requestObj.interviewGuid,
      jobSlug: ''
    };
    const rInterview: RecruiterInterview = await fetchInterviewRecruiterDetailServer(
      request
    );

    const jobDashboardInterviews: JobDashboardSearchResponse[] =
      SessionStorage.getItem('app-job-dashboard-interviews') || [];

    jobDashboardInterviews.forEach(element => {
      element.interviews.forEach(interview => {
        if (interview.slug === requestObj.interviewGuid) {
          interview.candidate.fullEmail = rInterview.candidate.fullEmail;
          interview.candidate.fullMobile = rInterview.candidate.fullMobile;
          interview.candidate.fullName = rInterview.candidate.fullName;
        }
      });
    });
    await setToSessionStorage(
      'app-job-dashboard-interviews',
      jobDashboardInterviews
    );

    return new Promise(async (resolve, reject) => {
      try {
        const jobDashboardInterviews: JobDashboardSearchResponse[] =
          SessionStorage.getItem('app-job-dashboard-interviews') || [];

        let dInterview;

        jobDashboardInterviews.forEach(element => {
          element.interviews.forEach(tInterview => {
            if (tInterview.slug == requestObj.interviewGuid) {
              dInterview = tInterview;
              if (requestObj.transitionStatus === 'unlocked') {
                tInterview.unlocked = true;
                tInterview.candidate.name =
                  tInterview.candidate.fullName || tInterview.candidate.name;
                if (tInterview.candidate.fullMobile) {
                  tInterview.candidate.mobile = tInterview.candidate.fullMobile;
                }
                if (tInterview.candidate.fullEmail) {
                  tInterview.candidate.email = tInterview.candidate.fullEmail;
                }
              } else {
                tInterview.status = getStatusFromCriteria(
                  requestObj.transitionStatus
                );
              }
            }
          });
        });

        await setToSessionStorage(
          'app-job-dashboard-interviews',
          jobDashboardInterviews
        );
        const response: any = await getInterviewRecruiterDetail(
          rInterview,
          dInterview
        );
        resolve(response);
      } catch (err) {
        console.log(err);
        reject(new Error('INTERVIEW_STATUS_TRANSITION_FAILED'));
      }
    });
  } catch (err) {
    console.log(err);
    return Promise.reject(new Error('INTERVIEW_STATUS_TRANSITION_FAILED'));
  }
}

export async function getAllJobClustersMock(): Promise<JobClusterItem[]> {
  const response: JobClusterItem[] = [];
  for (let x = 1; x < 20; x++) {
    const clusterItem: JobClusterItem = {
      slug: x.toString(),
      name: 'Mock Name:' + x,
      groupedJobClusters: [],
      introText: 'Mock IntroText:' + x,
      originIds: [],
      jobIds: [],
      jobNames: [],
      clusterJobs: [],
      addExternalId: false,
      salutationType: 'FORMAL'
    };
    response.push(clusterItem);
  }

  return Promise.resolve(response);
}

export async function saveJobClusterMock(
  requestObj: SaveJobClusterRequest
): Promise<JobClusterItem> {
  const returnObj: JobClusterItem = {
    slug: requestObj.slug,
    name: requestObj.name,
    introText: requestObj.introText,
    originIds: requestObj.originIds,
    groupedJobClusters: [],
    jobIds: [],
    jobNames: [],
    clusterJobs: [],
    addExternalId: false,
    salutationType: 'FORMAL'
  };
  return Promise.resolve(returnObj);
}

export async function updateUserStatusMock(
  requestObj: UpdateUserStatusRequest
): Promise<User> {
  const returnObj: User = {
    username: requestObj.username,
    firstName: 'Test First Name',
    lastName: 'Test Last Name',
    slug: '-1',
    active: 'ACTIVE' === requestObj.status,
    roleLevel: 'RECRUITER_WRITE',
    userAdminRole: 'NONE',
    notifyNewInterviews: false,
    notifyDailySummary: false,
    notifyUnreadChatMessages: false,
    allJobsAndSitesuser: false,
    tfaRequired: false,
    tfaEnabled: false
  };
  return Promise.resolve(returnObj);
}

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/plugins/i18n';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import langDe from 'quasar/lang/de';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';;
import VueQrcode from '@chenfengyuan/vue-qrcode';
import 'quasar/dist/quasar.css';
import '@/assets/main.css';
import VueAuthImage from 'vue-auth-image';
import { authMedia } from './vue-auth-media';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import './css/vueflow.css'

const app = createApp(App);

// Use the Viewer plugin
const viewerOptions = {
  defaultOptions: {
    inline: false,
    toolbar: false,
    navbar: false,
    title: false,
    button: true,
    movable: false,
    rotatable: false,
    transition: true,
    url: 'src'
  }
};

app.use(router);
app.use(store);
app.use(i18n);
app.use(VueViewer as any, viewerOptions);


// eslint-disable-next-line vue/multi-word-component-names
app.component('qrcode', VueQrcode);
app.directive('auth-image', VueAuthImage);
// Register the custom directive globally
app.directive('auth-media', authMedia);

// Use Quasar with options
app.use(Quasar as any, {
    config: {
        brand: {
          primary: '#a22263',
          secondary: '#4f474b',
          accent: '#7ac2cb',
          $whatsapp: '#45bd59',
          positive: '#21ba45',
          negative: '#ff8000',
          info: '#31ccec',
          warning: '#f2c037',
          'typography-font-family': 'Open Sans, Roboto, Helvetica, Arial, sans-serif',
        }
      },
    plugins: {
      Notify,
      LocalStorage,
      SessionStorage,
    },
    animations: 'all',
    cssAddon: true,
    lang: langDe,
  });
   
app.mount('#app');
export { app };

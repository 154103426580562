<template>
  <q-layout view="hhh lpr fff" class="shadow-3">
    <q-header elevated>
      <div class="bg-primary text-white">
        <div class="hf-container flex column justify-between full-height">
          <div class="toolbar-nav flex justify-between items-center">
            <div class="toolbar-nav-left">
              <div class="flex row justify-between content-center">
                <!-- <div class="navbar-menu">
                <q-btn
                  no-caps
                  flat
                  label="Menu"
                  icon-right="menu"
                  class="text-subtitle1 text-bold"
                >
                  <q-menu
                    auto-close
                    target=".toolbar-nav"
                    fit
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-list class="navbar-menu-menu">
                      <q-item
                        clickable
                        :to="{ name: 'dashboard' }"
                        active-class="active-nav-item"
                      >
                        <q-item-section avatar>
                          <q-icon name="dashboard" />
                        </q-item-section>
                        <q-item-section class="text-bold">{{
                          $t('message.dashboardLbl')
                        }}</q-item-section>
                      </q-item>
                      <q-separator />
                      <q-item
                        clickable
                        :to="{ name: 'managejobs' }"
                        active-class="active-nav-item"
                      >
                        <q-item-section avatar>
                          <q-icon name="business_center" />
                        </q-item-section>
                        <q-item-section class="text-bold">{{
                          $t('message.manageJobsLbl')
                        }}</q-item-section>
                      </q-item>
                      <q-separator />
                      <q-item
                        clickable
                        :to="{ name: 'applicants' }"
                        active-class="active-nav-item"
                      >
                        <q-item-section avatar>
                          <q-icon name="dashboard" />
                        </q-item-section>
                        <q-item-section class="text-bold">{{
                          $t('message.applicantSearchLbl')
                        }}</q-item-section>
                      </q-item>
                      <q-item
                        v-if="showMasterDataTab"
                        clickable
                        :to="{ name: 'masterdata' }"
                        active-class="active-nav-item"
                      >
                        <q-item-section avatar>
                          <q-icon name="business" />
                        </q-item-section>
                        <q-item-section class="text-bold">{{
                          $t('message.masterDataLbl')
                        }}</q-item-section>
                      </q-item>
                      <q-item
                        v-if="showSettingsTab"
                        clickable
                        :to="{ name: 'tenant_admin' }"
                        active-class="active-nav-item"
                      >
                        <q-item-section avatar>
                          <q-icon name="settings" />
                        </q-item-section>
                        <q-item-section class="text-bold">{{
                          $t('message.tenant_adminLbl')
                        }}</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div> -->
                <div>
                  <div>
                    <div class="navbar-tabs">
                      <q-tabs
                        no-caps
                        inline-label
                        dense
                        switch-indicator
                        align="left"
                        class="text-white menu-tabs"
                        v-model="selectedTopLevelNav"
                        active-bg-color="primary"
                      >
                        <q-tab
                          @click="onPitchYouNavSelected"
                          name="pitchyou"
                          label="PitchYou"
                          v-show="isPitchYouAccessible()"
                        />
                        <q-tab
                          @click="onRecruitMeNavSelected"
                          name="recruitme"
                          label="RecruitMe"
                          v-show="isRecruitMeAccessible()"
                        />
                        <q-tab
                          @click="onReportsNavSelected"
                          name="reports"
                          label="Reports"
                        />
                      </q-tabs>
                    </div>
                  </div>

                  <!-- <q-btn-dropdown
                  no-caps
                  unelevated
                  label="PitchYou"
                  class="text-subtitle1 text-bold"
                >
                  <q-list>
                    <q-item
                      clickable
                      :to="{ name: 'dashboard' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="dashboard" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.dashboardLbl')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item
                      clickable
                      :to="{ name: 'managejobs' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="business_center" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.manageJobsLbl')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item
                      clickable
                      :to="{ name: 'applicants' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="person_pin" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.applicantSearchLbl')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item
                      clickable
                      :to="{ name: 'reports' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="insights" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.reportsLbl')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                  </q-list>
                </q-btn-dropdown>
                <q-btn-dropdown
                  no-caps
                  unelevated
                  label="RecruitMe"
                  class="text-subtitle1 text-bold"
                >
                  <q-list>
                    <q-item
                      clickable
                      :to="{ name: 'messagecenter' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="question_answer" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.recruitMeMessagesLbl')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item
                      clickable
                      :to="{ name: 'assessmentruns' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="psychology" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.assessmentRunsHeader')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item
                      clickable
                      :to="{ name: 'questionnaire' }"
                      active-class="active-nav-item"
                    >
                      <q-item-section avatar>
                        <q-icon name="format_list_numbered" />
                      </q-item-section>
                      <q-item-section class="text-bold">{{
                        $t('message.questionnaireMenuTitle')
                      }}</q-item-section>
                    </q-item>
                    <q-separator />
                  </q-list>
                </q-btn-dropdown> -->
                </div>
                <!-- <div v-else>
                  <div class="navbar-tabs" v-if="showDefaultHeaderMenu">
                    <div>
                      <div class="navbar-tabs">
                        <q-tabs
                          no-caps
                          inline-label
                          dense
                          switch-indicator
                          align="left"
                          class="text-white"
                          v-model="selectedTopLevelNav"
                          active-bg-color="primary"
                        >
                          <q-tab
                            @click="onPitchYouNavSelected"
                            name="pitchyou"
                            label="PitchYou"
                            v-show="!reportsOnlyAccess"
                          />
                          <q-tab
                            @click="onReportsNavSelected"
                            name="reports"
                            label="Reports"
                          />
                        </q-tabs>
                      </div>
                    </div>
                  </div>
                  <div v-if="showMessageCenterMenu" class="navbar-tabs">
                    <q-tabs
                      no-caps
                      inline-label
                      align="left"
                      class="text-white"
                      v-model="toolbarName"
                      active-bg-color="primary"
                    >
                      <q-route-tab
                        name="messagecenter"
                        icon="question_answer"
                        :label="$t('message.messageCenterTitle')"
                        :to="{ name: 'messagecenter' }"
                      />
                      <q-route-tab
                        v-if="bulkMessageCred || superAdminCred"
                        name="bulkmessages"
                        icon="campaign"
                        :label="$t('message.bulkMessagesHeader')"
                        :to="{ name: 'bulkmessages' }"
                      />
                      <q-route-tab
                        v-if="assessmentCred || superAdminCred"
                        name="assessments"
                        icon="psychology"
                        :label="$t('message.assessmentRunsHeader')"
                        :to="{ name: 'assessmentruns' }"
                      />
                      <q-route-tab
                        v-if="assessmentCred || superAdminCred"
                        name="questionnaire"
                        icon="format_list_numbered"
                        :label="$t('message.questionnaireMenuTitle')"
                        :to="{ name: 'questionnaire' }"
                      />
                      <q-route-tab
                        name="contactsimport"
                        icon="upload_file"
                        :label="$t('message.contactsImportHeader')"
                        :to="{ name: 'contactsimport' }"
                      />
                    </q-tabs>
                  </div>
                </div> -->
              </div>
            </div>
            <div>
              <TenantInfo :tenant="getTenantInfo()" />
            </div>
            <div class="row items-center">
              <div class="toolbar-nav-right ">
                <q-btn-dropdown
                  flat
                  unelevated
                  no-caps
                  v-if="user"
                  class="text-subtitle1"
                >
                  <template v-slot:label>
                    <div v-if="$q.platform.is.mobile">
                      <q-icon name="person" />
                    </div>
                    <div v-else class="header-username ellipsis">
                      {{ username }}
                    </div>
                  </template>
                  <q-list>
                    <q-item
                      clickable
                      no-caps
                      :to="{ name: 'superadminconfig' }"
                      v-if="superAdminCred"
                    >
                      <q-item-section side>
                        <q-icon name="admin_panel_settings" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.superAdminConfigLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      no-caps
                      :to="{ name: 'masterdata' }"
                      v-if="showMasterDataTab"
                    >
                      <q-item-section side>
                        <q-icon name="business" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.masterDataLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      no-caps
                      :to="{ name: 'tenant_admin' }"
                      v-if="showSettingsTab"
                    >
                      <q-item-section side>
                        <q-icon name="group" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.tenant_adminLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable no-caps @click="showPreferredLanguage">
                      <q-item-section side>
                        <q-icon name="language" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.preferredLanguageLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      no-caps
                      @click="showTagsManagementDialog = true"
                      v-show="!reportsOnlyAccess || superAdminCred"
                    >
                      <q-item-section side>
                        <q-icon name="local_offer" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.manageTagsLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      v-if="isTfaEnabledForTenant && !isTfaEnforcedForAllUsers"
                    >
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.enableTFA')
                        }}</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-toggle
                          v-model="user.tfaEnabled"
                          keep-color
                          color="primary"
                          size="lg"
                          @update:model-value="onChangeTFA"
                        />
                      </q-item-section>
                    </q-item>
                    <div
                      v-if="
                        showDefaultHeaderMenu &&
                          (!reportsOnlyAccess || superAdminCred)
                      "
                    >
                      <q-separator />
                      <q-item-label header>{{
                        $t('message.emailNotificationsLbl')
                      }}</q-item-label>
                      <q-item>
                        <q-item-section>
                          <q-item-label>{{
                            $t('message.notifyNewInterviewsLbl')
                          }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-toggle
                            v-model="user.notifyNewInterviews"
                            keep-color
                            color="primary"
                            size="lg"
                            @update:model-value="onChangeNotification"
                          />
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <q-item-label>{{
                            $t('message.notifyDailySummaryLbl')
                          }}</q-item-label>
                        </q-item-section>
                        <q-item-section side top>
                          <q-toggle
                            v-model="user.notifyDailySummary"
                            keep-color
                            color="primary"
                            size="lg"
                            @update:model-value="onChangeNotification"
                          />
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <q-item-label>{{
                            $t('message.notifyUnreadChatMessagesLbl')
                          }}</q-item-label>
                        </q-item-section>
                        <q-item-section side top>
                          <q-toggle
                            v-model="user.notifyUnreadChatMessages"
                            keep-color
                            color="primary"
                            size="lg"
                            @update:model-value="onChangeNotification"
                          />
                        </q-item-section>
                      </q-item>
                    </div>

                    <q-separator />
                    <q-item
                      clickable
                      no-caps
                      @click="openGoogleRatingPage"
                      v-close-popup
                    >
                      <q-item-section side>
                        <q-icon name="fab fa-google" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.googleRatingLbl')
                        }}</q-item-label>
                      </q-item-section>
                      <q-tooltip
                        >{{ $t('message.googleRatingTooltip') }}
                      </q-tooltip>
                    </q-item>
                    <!-- <q-item
                      clickable
                      no-caps
                      @click="openNewSupportTicketMail"
                      v-close-popup
                    >
                      <q-item-section side>
                        <q-icon name="contact_support" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.openNewSupportTicketMailLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item> -->
                    <q-item
                      clickable
                      v-close-popup
                      @click="onClickChangePassword"
                    >
                      <q-item-section side>
                        <q-icon name="security" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.changePwLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="onLogout">
                      <q-item-section side>
                        <q-icon name="eject" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $t('message.logoutLbl')
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
              <div>
                <q-btn
                  @click="openNewSupportTicketMail"
                  icon="contact_support"
                  dense
                  flat
                  ><q-tooltip
                    >{{ $t('message.openNewSupportTicketMailLbl') }}
                  </q-tooltip></q-btn
                >
              </div>
              <div v-if="renderMessageBtn">
                <q-btn flat icon="mail" @click="messageIconClicked">
                  <q-badge color="accent" floating v-if="unreadMessages > 0">{{
                    unreadMessages
                  }}</q-badge>
                </q-btn>
                <ServerNotification
                  @on-notification="onNotification"
                ></ServerNotification>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-grey-4 text-primary"
        v-if="isPitchYouAccessible() && selectedTopLevelNav == 'pitchyou'"
      >
        <q-tabs
          no-caps
          inline-label
          dense
          narrow-indicator
          align="center"
          v-model="toolbarName"
          active-bg-color="grey-5"
          class="menu-tabs" 
        >
          <q-route-tab
            name="dashboard"
            icon="dashboard"
            :label="$t('message.dashboardLbl')"
            :to="{ name: 'dashboard' }"
            class="q-ml-xl"
          />
          <q-route-tab
            name="managejobs"
            icon="business_center"
            :label="$t('message.manageJobsLbl')"
            :to="{ name: 'managejobs' }"
          />
          <q-route-tab
            name="applicants"
            icon="person_pin"
            :label="$t('message.applicantSearchLbl')"
            :to="{ name: 'applicants' }"
          />
          <q-route-tab
            name="pitchyouquestionnaire"
            icon="format_list_numbered"
            :label="$t('message.questionnaireMenuTitle')"
            :to="{ name: 'recruiterquestionnaire' }"
            v-show="enableCampaigns"
          />
        </q-tabs>
      </div>

      <div
        class="bg-grey-4 text-primary"
        v-if="isRecruitMeAccessible() && selectedTopLevelNav == 'recruitme'"
      >
        <q-tabs
          no-caps
          inline-label
          dense
          narrow-indicator
          align="center"
          v-model="toolbarName"
          active-bg-color="grey-5"
          class="menu-tabs" 
        >
          <q-route-tab
            name="messagecenter"
            icon="question_answer"
            :label="$t('message.messageCenterTitle')"
            :to="{ name: 'messagecenter' }"
            class = "q-ml-xl"
          />
          <q-route-tab
            v-if="bulkMessageCred || superAdminCred"
            name="bulkmessages"
            icon="campaign"
            :label="$t('message.bulkMessagesHeader')"
            :to="{ name: 'bulkmessages' }"
         />
          <q-route-tab
            v-if="assessmentCred || superAdminCred"
            name="assessments"
            icon="psychology"
            :label="$t('message.assessmentRunsHeader')"
            :to="{ name: 'assessmentruns' }"
          />
          <q-route-tab
            v-if="assessmentCred || superAdminCred"
            name="questionnaire"
            icon="format_list_numbered"
            :label="$t('message.questionnaireMenuTitle')"
            :to="{ name: 'questionnaire' }"
            class = "q-mr-md"
          />
          <q-route-tab
            name="contactsimport"
            icon="upload_file"
            :label="$t('message.contactsImportHeader')"
            :to="{ name: 'contactsimport' }"
            class = "q-mr-xl"
          />
        </q-tabs>
      </div>
      <div class="bg-grey-4 text-primary" v-show="showReportsTabs()">
        <q-tabs
          no-caps
          inline-label
          dense
          narrow-indicator
          align="center"
          v-model="toolbarValue"
          active-bg-color="grey-5"
        >
          <q-route-tab
            name="reports"
            icon="insights"
            label="PitchYou"
            :to="{ name: 'reports' }"
          />
          <q-route-tab
            name="conversationreports"
            icon="bar_chart"
            label="RecruitMe"
            :to="{ name: 'conversationreports' }"
            style = "margin-right : 10%"
           />
        </q-tabs>
      </div>
    </q-header>
    <q-page-container>
      <router-view />
      <q-dialog
        v-model="showMessageDialog"
        v-if="showMessageDialog"
        position="right"
      >
        <q-card>
          <q-infinite-scroll
            @load="loadMoreContacts"
            :offset="500"
            scroll-target="#scroll-target-id"
          >
            <q-card-section class="q-pa-xs">
              <q-list
                bordered
                separator
                class="rounded-borders"
                style="max-width: 400px"
              >
                <q-item-label header class=" q-pa-xs bg-secondary">
                  <div class="row items-center justify-center text-white">
                    <div v-if="showMessagesLoadingSpinner" class="q-pr-xl">
                      <q-spinner> </q-spinner>
                    </div>
                    <q-icon name="mail" size="sm"></q-icon>
                    <div class="text-h6 q-pl-sm">
                      {{ $t('message.messageCenterLbl') }}
                    </div>
                  </div>
                </q-item-label>
                <q-separator />
                <div
                  id="scroll-target-id"
                  style="max-height: 600px;min-height:600px; overflow: auto; min-width: 400px"
                >
                  <q-item
                    clickable
                    v-ripple
                    v-for="message in messages"
                    :key="message.slug"
                    @click="goToInterviewChat(message.guid)"
                  >
                    <q-item-section avatar>
                      <q-avatar>
                        <img
                          v-auth-media="message.avatarUri"
                          v-if="message.avatarUri"
                          class="img-fluid rounded-borders"
                        />
                        <img
                          src="../assets/unidentified.png"
                          v-else
                          class="img-fluid rounded-borders"
                        />
                      </q-avatar>
                    </q-item-section>
                    <q-item-section
                      :class="
                        !message.read && !message.sent ? 'text-weight-bold' : ''
                      "
                    >
                      <q-item-label lines="1">{{ message.name }}</q-item-label>

                      <q-item-label caption lines="2">
                        <q-badge
                          color="accent"
                          v-if="message.unreadMessages > 0"
                          >{{ message.unreadMessages }}</q-badge
                        >
                        <div
                          v-if="'TEXT' == message.mediaType"
                          :class="
                            !message.read && !message.sent
                              ? 'text-weight-bold'
                              : ''
                          "
                        >
                          {{ message.text }}
                        </div>
                        <div v-else>
                          <q-icon
                            :name="getMediaTypeIconName(message.mediaType)"
                          />
                        </div>
                      </q-item-label>
                    </q-item-section>

                    <q-item-section
                      side
                      top
                      :class="
                        !message.read && !message.sent ? 'text-weight-bold' : ''
                      "
                    >
                      {{ getFormattedDate(message.sentAt) }}
                    </q-item-section>
                  </q-item>
                </div>
              </q-list></q-card-section
            >
          </q-infinite-scroll>
        </q-card>
      </q-dialog>
      <q-dialog v-model="isShowChPwDialog" persistent>
        <q-card style="min-width: 350px">
          <q-card-section class="row items-center">
            <div class="text-h6">
              {{ $t('message.passwordChangeHeader') }}
            </div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>
          <q-card-section>
            <q-input
              filled
              :type="isHidePassword ? 'password' : 'text'"
              v-model="newPassword"
              :disable="showTFAforPasswordChangeDialog"
              autofocus
              color="primary"
              :rules="[
                val =>
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&äÄöÖüÜ])[A-Za-z\d$@$!%*?&äÄöÖüÜ]{8,}/.test(
                    val
                  ) || $t('message.safePasswordRuleLbl')
              ]"
            >
              <template v-slot:append>
                <q-icon
                  :name="isHidePassword ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isHidePassword = !isHidePassword"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section v-show="showTFAforPasswordChangeDialog">
            <div class="text-h7">
              {{ $t('message.tfaForPasswordChangeLbl') }}
            </div>
            <!-- <div class="row items-center">
              <div>
                {{
                  $t('message.tfaReqMsg', {
                    1: username
                  })
                }}
              </div>
            </div> -->
            <q-input
              filled
              clearable
              color="primary"
              ref="passwordChangeTfaCodeRef"
              v-model="passwordChangeTfaCode"
              :label="$t('message.tfaCodeLbl')"
              autofocus
              lazy-rules
              :rules="[
                val =>
                  (val && val.length == 6) ||
                  $t('message.tfaReqMsg', {
                    1: user ? user.username : username
                  })
              ]"
            >
            </q-input>
          </q-card-section>
          <q-separator />
          <q-card-actions align="right" class="text-primary">
            <q-btn
              v-if="
                isTFARequiredForPasswordChange &&
                  !showTFAforPasswordChangeDialog
              "
              :label="$t('message.nextLbl')"
              no-caps
              color="primary"
              @click="sendTFACodeForPasswordChange"
            />
            <!-- <q-btn
              v-show="
                isTFARequiredForPasswordChange && showTFAforPasswordChangeDialog
              "
              :label="$t('message.verifyLbl')"
              no-caps
              color="primary"
              @click="verifyTFACodeForPasswordChange"
            /> -->
            <q-btn
              v-else
              :label="$t('message.saveLbl')"
              color="primary"
              @click="onChangePassword"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-dialog v-model="showPreferredLanguageDialog" persistent>
        <q-card style="min-width: 350px">
          <q-card-section class="row items-center">
            <div class="text-h6">
              {{ $t('message.setPreferredLanguageLbl') }}
            </div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>
          <q-card-section>
            <q-card>
              <div class="q-pa-md">
                <q-select
                  filled
                  dense
                  map-options
                  class="col-5"
                  :options="languageOptions"
                  v-model="preferredLanguage"
                  color="primary"
                  :label="$t('message.preferredLanguageLbl')"
                ></q-select>
              </div>
              <div class="row full-width justify-end q-pa-md">
                <q-btn
                  color="primary"
                  @click="updatePreferredLanguage"
                  icon="save"
                  :label="$t('message.updateContactLbl')"
                ></q-btn>
              </div>
            </q-card>
          </q-card-section>
        </q-card>
      </q-dialog>
      <q-dialog v-model="showTagsManagementDialog" persistent>
        <TagsManagement :loadTags="true"></TagsManagement>
      </q-dialog>
      <!-- <q-dialog v-model="showTFAforPasswordChangeDialog" persistent>
        <q-card style="min-width: 40vw; max-width: 80vw;">
          <q-card-section class="row items-center">
            <div class="text-h6">
              {{ $t('message.tfaCodeLbl') }}
            </div>
            <q-space />
            <q-btn
              icon="close"
              flat
              round
              dense
              @click="onCloseTFAforPasswordChangeDialog"
            />
          </q-card-section>
          <q-separator />
          <q-card-section style="max-height: 40vh" class="scroll">
            <div class="text-h7">
              {{ $t('message.tfaForPasswordChangeLbl') }}
            </div>
             <div class="row items-center">
              <div>
                {{
                  $t('message.tfaReqMsg', {
                    1: username
                  })
                }}
              </div>
            </div>
            <q-input
              filled
              clearable
              color="primary"
              ref="passwordChangeTfaCode"
              v-model="passwordChangeTfaCode"
              :label="$t('message.tfaCodeLbl')"
              autofocus
              lazy-rules
              :rules="[
                val =>
                  (val && val.length == 6) ||
                  $t('message.tfaReqMsg', {
                    1: user ? user.username : username
                  })
              ]"
            >
            </q-input>
          </q-card-section>
          <q-separator />
          <q-card-actions align="right">
            <q-btn
              icon="verified_user"
              :label="$t('message.verifyLbl')"
              color="primary"
              @click="verifyTFA"
              no-caps
            />
          </q-card-actions>
        </q-card>
      </q-dialog> -->
      <q-dialog v-model="showProductInfoDialog" persistent>
        <q-card style="min-width: 80%; min-height: 82vh">
          <div class="row">
            <div class="fit row justify-end">
              <q-btn icon="close" flat round dense v-close-popup />
            </div>
            <iframe
              :src="infoNoteTargetUrl"
              style="min-width: 100%; height: 78vh"
            ></iframe>
            <div class="fit row justify-end q-pr-sm">
              <q-checkbox v-model="hideInfoNote" @update:model-value="updateInfoNote"
                >{{ $t('message.hideInfoNoteLbl') }}
              </q-checkbox>
            </div>
          </div></q-card
        >
      </q-dialog>
      <q-dialog v-model="showTfaConfirmationCodeDialog" persistent>
        <q-card style="min-width: 40vw; max-width: 80vw;">
          <q-card-section class="row items-center">
            <div class="text-h6">
              {{ $t('message.enableTFA') }}
            </div>
            <q-space />
            <q-btn
              icon="close"
              flat
              round
              dense
              @click="onCloseTfaConfirmationCodeDialog"
            />
          </q-card-section>
          <q-separator />
          <q-card-section style="max-height: 40vh" class="scroll">
            <!-- <div class="row items-center">
              <div>
                {{
                  $t('message.tfaReqMsg', {
                    1: username
                  })
                }}
              </div>
            </div> -->
            <q-input
              filled
              clearable
              color="primary"
              ref="tfaCodeRef"
              v-model="tfaCode"
              :label="$t('message.tfaCodeLbl')"
              autofocus
              lazy-rules
              :rules="[
                val =>
                  (val && val.length == 6) ||
                  $t('message.tfaReqMsg', {
                    1: user ? user.username : username
                  })
              ]"
            >
            </q-input>
          </q-card-section>
          <q-separator />
          <q-card-actions align="right">
            <q-btn
              icon="save"
              :label="$t('message.saveLbl')"
              color="primary"
              @click="enableTfa"
              no-caps
              dense
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <LoadingIndicator :showLoading="showLoadingIndicator"></LoadingIndicator>
    </q-page-container>
    <BrandFooter />
  </q-layout>
</template>

<script setup lang="ts">
import { ref, onMounted as vueOnMounted,  watch, computed} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

import BrandFooter from '@/components/BrandFooter.vue';
import TenantInfo from '@/components/TenantInfo.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import {
  Tenant,
  User,
  LoginRequest,
  ChangePasswordRequest,
  NotificationMessage,
  ChatMessageRequest,
  ChatMessage,
  TenantMessageInfo,
  ILabelValue,
  CountriesOptionResponse,
  AllChatMessageRequestDto,
  WipeOutAuthCredentialsDto
} from '../store/models';
import tenantModule from '@/store/modules/tenantModule';
import userModule from '@/store/modules/userModule';
import { showSuccessMsgToast, showErrorMsgToast } from '@/common';
import ServerNotification from '@/components/ServerNotification.vue';
import chatMessageModule from '@/store/modules/chatMessageModule';
import CreateNewTenantComp from '@/components/CreateNewTenantComp.vue';
import notificationSound from '@/assets/message_notification.mp3';
import jobAdministrationModule from '@/store/modules/jobAdministrationModule';
import findIndex from 'lodash-es/findIndex';
import TagsManagement from '../components/TagsManagement.vue';


const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();

// Define reactive variables
const onMounted = vueOnMounted;
const name = 'MainLayout';
const tenant = ref<Tenant | null>(null);
const toolbarValue = ref<string>('');
const isShowChPwDialog = ref<boolean>(false);
const newPassword = ref<string>('');
const isHidePassword = ref<boolean>(true);
const currentUser = ref<string>('');
const showMessageDialog = ref<boolean>(false);
const messages = ref<ChatMessage[]>([]);
const tenantMessageInfo = ref<TenantMessageInfo | undefined>(undefined);
const renderMessageBtn = ref<boolean>(false);
const unreadMessages = ref<number>(0);

const showMasterDataTab = ref<boolean | null>(false);
const showSettingsTab = ref<boolean | null>(false);
const showCreateTenantTab = ref<boolean | null>(false);
const showMessagesLoadingSpinner = ref<boolean>(true);

const currentCreateTenantWizardStep = ref<string>('1');
const messageCenterStandalone = ref<boolean>(false);
const showDefaultHeaderMenu = ref<boolean>(false);
const showMessageCenterMenu = ref<boolean>(false);
const messageCenterFeature = ref<boolean>(false);
const messageCenterCred = ref<boolean | null>(false);
const superAdminCred = ref<boolean | null>(false);
const showNavigationDropdown = ref<boolean>(false);
const selectedTopLevelNav = ref<string>('pitchyou');
const showPreferredLanguageDialog = ref<boolean>(false);
const languageOptions = ref<ILabelValue[]>([]);
const languageOptionsResponse = ref<CountriesOptionResponse | null>(null);


const preferredLanguage = ref<ILabelValue | null>(null);
const showLoadingIndicator = ref<boolean>(false);
const currentPageNumber = ref<number>(0);
const totalPages = ref<number>(0);
const pageLimit = ref<number>(50);
const nextPageNumber = ref<number>(0);
const bulkMessageCred = ref<boolean | null>(false);
const assessmentCred = ref<boolean | null>(false);
const showTagsManagementDialog = ref<boolean>(false);
const reportsOnlyAccess = ref<boolean>(false);
const showProductInfoDialog = ref<boolean>(false);
const infoNoteTargetUrl = ref<string | null>(null);
const hideInfoNote = ref<boolean>(false);
const remoteSystemTenant = ref<boolean>(false);
const externalChatTokenCreation = ref<boolean>(false);
const enableCampaigns = ref<boolean>(false);
const isTfaEnabledForTenant = ref<boolean>(false);
const isTfaEnforcedForAllUsers = ref<boolean>(false);
const showTfaConfirmationCodeDialog = ref<boolean>(false);
const tfaCode = ref<string | null>(null);

const passwordChangeTfaCode = ref<string | null>(null);
const showTFAforPasswordChangeDialog = ref<boolean>(false);
const isTFARequiredForPasswordChange = ref<boolean>(false);

const tfaCodeRef = ref();
const passwordChangeTfaCodeRef = ref();
const user = ref<User | null>(userModule.loggedInUser);

onMounted(async () => {
  showMasterDataTab.value =
    (await userModule.isCred('ADMIN')) ||
    (await userModule.isCred('SUPERADMIN'));

  await loadUserPreferredLanguage();
  showSettingsTab.value = await userModule.isGroupAdmin();
  superAdminCred.value = await userModule.isCred('SUPERADMIN');
  messageCenterCred.value = await userModule.isFeatureCred('MESSAGE_CENTER');
  bulkMessageCred.value = await userModule.isFeatureCred('BULK_MESSAGES');
  assessmentCred.value = await userModule.isFeatureCred('ASSESSMENTS');
  showCreateTenantTab.value = superAdminCred.value;

  await loadTenantInfo();

  if (userModule.loggedInUser) {
    await loadInfoNote();
    await loadLanguageOptions();
    await loadTenantMessageInfo();
  }

});

const onPitchYouNavSelected = () => {
  router.push({ name: 'dashboard' });
};

const onRecruitMeNavSelected = () => {
    router.push({ name: 'messagecenter' });
};

const onReportsNavSelected = () => {
    if (superAdminCred.value) {
      router.push({ name: 'reports' });
    } else {
      if (messageCenterStandalone.value) {
        router.push({ name: 'conversationreports' });
      } else {
        router.push({ name: 'reports' });
      }
    }
};

const loadUserPreferredLanguage = async () => {
    let user = userModule.loggedInUser;
    if (user && user.preferredLanguageCode) {
      locale.value = user.preferredLanguageCode;
    }
};

const loadTenantMessageInfo = async () => {
    try {
      if (!reportsOnlyAccess.value || superAdminCred.value) {
        const tenantMessageInfo: TenantMessageInfo = await tenantModule.loadTenantMessageInfo();
        renderMessageBtn.value = tenantMessageInfo.renderMessageBtn;
        unreadMessages.value = tenantMessageInfo.unreadMessages;
      }
    } catch (error) {
      console.log(error);
    }
};

const openNewSupportTicketMail = () => {
  window.open('mailto:support@pitchyou.de', '_blank');
};

const openGoogleRatingPage = () => {
  window.open('https://g.page/r/CUvjditF0dlGEBI/review', '_blank');
};

const loadTenantInfo = async () => {
  try {
    tenant.value = tenantModule.tenantInfo || null;
    if (!tenant.value) {
      await tenantModule.loadTenantInfo();
      tenant.value = tenantModule.tenantInfo;
    }

    if (tenant.value) {
      messageCenterStandalone.value = tenant.value.messageCenterStandalone as boolean;
      showDefaultHeaderMenu.value = !messageCenterStandalone.value;
      showMessageCenterMenu.value = messageCenterStandalone.value;
      messageCenterFeature.value = tenant.value.messageCenterFeature as boolean;
      reportsOnlyAccess.value = tenant.value.reportsOnlyAccess;
      remoteSystemTenant.value = tenant.value.remoteSystem;
      externalChatTokenCreation.value = tenant.value.externalChatTokenCreation as boolean;
      enableCampaigns.value = tenant.value.enableCampaigns;
      isTfaEnabledForTenant.value = tenant.value.tfaEnabled;
      isTfaEnforcedForAllUsers.value = tenant.value.tfaEnforcedForAllUsers;
      if (superAdminCred.value) {
        selectedTopLevelNav.value = 'pitchyou';
      } else if (reportsOnlyAccess.value) {
        selectedTopLevelNav.value = 'reports';
      } else if (messageCenterStandalone.value) {
        selectedTopLevelNav.value = 'recruitme';
      }
    }
    if (
      superAdminCred.value ||
      (messageCenterFeature.value && messageCenterCred.value)
    ) {
      showNavigationDropdown.value = true;
    }
  } catch (error) {
    console.log(error);
  }
  if (!tenant.value) {
    router.push({ name: 'invalidtenant' });
  }
};

const getTenantInfo = () => {
  return tenant.value ? tenant.value : tenantModule.tenantInfo;
};

const onLogout = (): void => {
  userModule.logoutUser();
  router.push({ name: 'home' });
};

const onChangePassword = async () => {
  try {
    showLoadingIndicator.value = true;
    if (newPassword.value) {
      let user = await userModule.loggedInUser;
      if (validatePassword(newPassword.value)) {
        if (isTfaEnforcedForAllUsers.value || (user && user.tfaEnabled)) {
                 if (passwordChangeTfaCodeRef.value != null) {
            const passwordChangeTfaCodeEl: any = passwordChangeTfaCodeRef.value; 
            passwordChangeTfaCodeEl.validate();
            if (passwordChangeTfaCodeEl.hasError) {
              return;
            }
          }
        }

        const changePasswordRequest: ChangePasswordRequest = {
          newPassword: newPassword.value,
          tfaCode: passwordChangeTfaCode.value,
        };
        await userModule.performChangePassword(changePasswordRequest);
        showSuccessMsgToast( t('message.passwordChangedSuccess') as string);
        newPassword.value = '';
        isShowChPwDialog.value = false;
      }
    }
  } catch (error) {
    console.error(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else if ((error as any)?.message && (error as any).message.includes('TFA_ERROR')) {
      showErrorMsgToast( t('message.invalidCodeLbl') as string);
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  } finally {
    showLoadingIndicator.value = false;
  }
};

   const loadInfoNote = async () => {
    try {
      const infoNote = await userModule.doGetInfoNote();
      if (infoNote && infoNote.targetUrl) {
        infoNoteTargetUrl.value = infoNote.targetUrl;
        showProductInfoDialog.value = true;
      }
    } catch (error) {
      console.log(error);
      if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
        router.push({ name: 'home' });
      } else {
        showErrorMsgToast( t('message.failedProcessMsg') as string);
      }
    }
  };

const updateInfoNote = () => {
    try {
      userModule.doUpdateInfoNote();
    } catch (error) {
      console.log(error);
      if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
        router.push({ name: 'home' });
      } else {
        showErrorMsgToast( t('message.failedProcessMsg') as string);
      }
    }
};

const validatePassword = (password: string): boolean => {
  return /^(?=.*[0-9])(?=.*[a-zäüö])(?=.*[A-ZÄÜÖ])(?=.*[-_;#+:,?=*.§!@$%^&(){}[\]]){8,32}/.test(
    password
  );
};

const onChangeNotification = async () => {
  try {
    if (user.value) {
      const updatedUser = await userModule.performNotificationsChange(user.value);
      userModule.setUser(updatedUser);
    }
  } catch (error) {
    console.error(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  }
};

const getMediaTypeIconName = (mediaType: string) => {
  switch (mediaType) {
    case 'IMAGE':
      return 'photo';
    case 'VIDEO':
      return 'voice_chat';
    case 'AUDIO':
      return 'record_voice_over';
    case 'DOCUMENT':
      return 'description';
    default:
      break;
  }
};

const  loadChatMessages = async () => {
  try {
    showMessagesLoadingSpinner.value = true;
    const request: AllChatMessageRequestDto = {
      page: currentPageNumber.value,
      limit: pageLimit.value,
      searchTerm: '',
      showNewContacts: false,
      unreadMessages: false
    };
    const response = await chatMessageModule.getAllChatMessages(request);
    showMessagesLoadingSpinner.value = false;
    totalPages.value = response.totalPages;
    if (response && response.contacts) {
      response.contacts.forEach(val =>
        messages.value.push(Object.assign({}, val))
      );
      nextPageNumber.value = nextPageNumber.value + 1;
      return response.contacts.length < 15;
    }
  } catch (error) {
    console.log(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  }
};

const getFormattedDate = (date: Date) => {
  if (date) {
    return new Date(date).toLocaleString();
  }

  return '';
};

const goToInterviewChat = (guid: string) => {
  const paramValues: any = {};
  paramValues.chat = '1';
  router.replace({
    name: 'interviewrecruiter',
    params: { interviewGuid: guid },
    query: paramValues
  });
  //PYS-2715 : Navigate to same page when second time clicked on notification items.
  router.afterEach(() => {
    router.go(0);
  });
};

const getLastMessageSlug = () => {
  if (messages.value && messages.value.length > 0) {
    return messages.value[messages.value.length - 1].slug;
  }
  return '';
};

const messageIconClicked = async () => {
  if (
    messageCenterStandalone.value ||
    superAdminCred.value ||
    (messageCenterFeature.value && messageCenterCred.value)
  ) {
    router.push({ name: 'messagecenter' });
  } else {
    currentPageNumber.value = 0;
    nextPageNumber.value = 0;
    showAllMessages();
  }
};

const showAllMessages = async () => {
  messages.value = [];
  showMessageDialog.value = true;
  await loadChatMessages();
  document.title = t('message.applicationTitle') as string;
};

 const onNotification = async (message: any) => {
    const notificationMessage: NotificationMessage = JSON.parse(message);
    if (
      notificationMessage &&
      ('NEW_MESSAGE' == notificationMessage.type ||
        'UPDATE_UNREADS' == notificationMessage.type)
    ) {
      /* const response = await chatMessageModule.getAllChatMessagesCount(); */
      const tenantMessageInfo: TenantMessageInfo = await new Promise<
        TenantMessageInfo
      >(resolve => {
        setTimeout(() => {
          resolve(tenantModule.loadTenantMessageInfo());
        }, 1000);
      });
      if (tenantMessageInfo) {
        const doPlaySound: boolean =
        unreadMessages.value < tenantMessageInfo.unreadMessages;
        unreadMessages.value = tenantMessageInfo.unreadMessages;
        if (doPlaySound) {
          const audio = new Audio(notificationSound);
          audio.loop = false;
          const playPromise = audio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(function() {
                // Automatic playback started!
              })
              .catch(function(error) {
                // Automatic playback failed.
              });
          }
          document.title = t('message.newNotificationsMsg', {
            unreadCount: unreadMessages.value
          }) as string;
        }
        if (unreadMessages.value == 0) {
          document.title = t('message.applicationTitle') as string;
        }
      }

      /*      const doPlaySound: boolean =
        unreadMessages < notificationMessage.unreadMessages;
      unreadMessages = notificationMessage.unreadMessages;
      if (doPlaySound) {
        const audio = new Audio(notificationSound);
        audio.loop = false;
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(function() {
              // Automatic playback started!
            })
            .catch(function(error) {
              // Automatic playback failed.
            });
        }
        document.title = $t('message.newNotificationsMsg', {
          unreadCount: unreadMessages
        }) as string;
      } */
    }
  };


function toolbarName() {
  return toolbarValue.value;
}

watch(() => route.meta, () => {
  if (route.meta) {
    toolbarValue.value = (route.meta.toolbarName || '') as string;
  }
});



  const username = computed(() => {
  return user.value && user.value.firstName + ' ' + user.value.lastName;
});

  const showPreferredLanguage = async () => {
    showLoadingIndicator.value = true;
    showPreferredLanguageDialog.value = true;
    let result = await userModule.doGetUserPreferredLanguage();
    if (result && result.preferredLanguageCode) {
      setPreferredLanguage(result.preferredLanguageCode);
    } else {
      setPreferredLanguage(navigator.language.split('-')[0]);
    }
    showLoadingIndicator.value = false;
  }
  const loadLanguageOptions = async () => {
  try {
    let languageOptionsData: CountriesOptionResponse = {
      countries: [
        {
          label: 'de',
          value: '🇩🇪'
        },
        {
          label: 'en',
          value: '🇬🇧'
        },
        {
          label: 'fr',
          value: '🇫🇷'
        },
        {
          label: 'nl',
          value: '🇳🇱'
        }
      ]
    };
    languageOptionsResponse.value = languageOptionsData;

    if (languageOptionsResponse.value) {
      languageOptionsResponse.value.countries.forEach(cou =>
        languageOptions.value.push({
          label: cou.value + ' ' + t('message.languageValue_' + cou.label),
          value: cou.label
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};

const setPreferredLanguage = (languageCode: string) => {
    const foundLanguage = languageOptions.value.find(
    (o) => o.value === languageCode
  );

  if (foundLanguage) {
    preferredLanguage.value = foundLanguage;
  } else {
    const defaultLanguage = languageOptions.value.find(
      (o) => o.value === 'de'
    );

    if (defaultLanguage) {
      preferredLanguage.value = defaultLanguage;
    }
  }
};

const updatePreferredLanguage = async () => {
  if (preferredLanguage.value && preferredLanguage.value.value) {
    await userModule.doUpdateUserPreferredLanguage(preferredLanguage.value.value);
    locale.value = preferredLanguage.value.value;

    const user = userModule.loggedInUser;
    if (user) {
      user.preferredLanguageCode = preferredLanguage.value.value;
      userModule.setUser(user);
    }
  }

  showPreferredLanguageDialog.value = false;
  router.go(0);
};

const loadMoreContacts = async (done: (stop: boolean) => void) => {
  if (nextPageNumber.value !== 0) {
    currentPageNumber.value += 1;

    if (currentPageNumber.value < totalPages.value) {
      await loadChatMessages();
      done(false);
    } else {
      done(true);
    }
  } else {
    done(false);
  }
};

const isPitchYouAccessible = () => {
  if (superAdminCred.value) {
    return true;
  } else if (reportsOnlyAccess.value) {
    return false;
  } else if (messageCenterStandalone.value) {
    return false;
  } else {
    return true;
  }
};

  const isRecruitMeAccessible = () => {
    if (superAdminCred.value) {
      return true;
    } else if (reportsOnlyAccess.value) {
      return false;
    } else if (messageCenterStandalone.value) {
      return true;
    } else if (messageCenterFeature.value && messageCenterCred.value) {
      return true;
    } else {
      return false;
    }
  };

  function showReportsTabs() {
    if (selectedTopLevelNav.value === 'reports') {
  if (superAdminCred.value) {
    return true;
  } else if (remoteSystemTenant.value && messageCenterStandalone.value) {
    return true;
  } else if (reportsOnlyAccess.value && !externalChatTokenCreation.value) {
    return false;
  } else if (messageCenterStandalone.value) {
    return false;
  }
  return (messageCenterFeature.value && messageCenterCred.value) || externalChatTokenCreation;
} else {
  return false;
}
  }


const  onChangeTFA = async () => {
  try {
    if (user.value) {
      showLoadingIndicator.value = true;
      if (user.value.tfaEnabled) {
        user.value.sendTfaConfirmationCode = true;
        user.value.tfaEnabled = false;
        await userModule.updateUserTfa(user.value);
        showSuccessMsgToast(t('message.tfaCodeSentByEmailMsg', {
            1: user.value ? user.value.username : username.value
          }) as string
        );
        showTfaConfirmationCodeDialog.value = true;
      } else {
        await userModule.updateUserTfa(user.value);
        userModule.setUser(user.value);
      }
    }
  } catch (error) {
    console.log(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  } finally {
    showLoadingIndicator.value = false;
  }
};

const enableTfa = async () => {
  try {
    if (user.value) {
      const tfaCodeRefValue: any = tfaCodeRef.value;
      tfaCodeRefValue.validate();
      if (tfaCodeRefValue.hasError) {
        return;
      }
      showLoadingIndicator.value = true;
      user.value.sendTfaConfirmationCode = false;
      user.value.tfaEnabled = true;
      user.value.tfaCode = tfaCode.value as string;
      await userModule.updateUserTfa(user.value);
      tfaCode.value = null;
      user.value.tfaCode = undefined;
      userModule.setUser(user.value);
      showTfaConfirmationCodeDialog.value = false;
      showSuccessMsgToast(
        t('message.tfaEnabledSuccessMsg') as string
      );
    }
  } catch (error) {
    console.log(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else if (
      (error as any)?.message && (error as any).message.includes('TFA_CODE_ERROR')
    ) {
      showErrorMsgToast( t('message.invalidCodeLbl') as string);
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  } finally {
    showLoadingIndicator.value = false;
  }
};

const onCloseTfaConfirmationCodeDialog = async () => {
  if (user.value) {
    user.value.sendTfaConfirmationCode = false;
    user.value.tfaEnabled = false;
    tfaCode.value = null;
    showTfaConfirmationCodeDialog.value = false;
  }
};

const onClickChangePassword = async () => {
  let user = await userModule.loggedInUser;
  if (isTfaEnforcedForAllUsers.value || (user && user.tfaEnabled)) {
    //await sendTFACode();
    passwordChangeTfaCode.value = null;
    isTFARequiredForPasswordChange.value = true;
  }
  newPassword.value = '';
  showTFAforPasswordChangeDialog.value = false;
  isShowChPwDialog.value = true;
};

const  sendTFACodeForPasswordChange = async () => {
  if (newPassword.value) {
    if (validatePassword(newPassword.value)) {
      await sendTFACode();
      showTFAforPasswordChangeDialog.value = true;
    }
  }
};

const  verifyTFACodeForPasswordChange = async () => {
  try {
    const passwordChangeTfaCodeRefValue: any = passwordChangeTfaCodeRef.value;
    passwordChangeTfaCodeRefValue.validate();
    if (passwordChangeTfaCodeRefValue.hasError) {
      return;
    }
    showLoadingIndicator.value = true;
    const response = await userModule.verifyTFACode(
      passwordChangeTfaCode.value as string
    );
    if (response.validCode) {
      await onChangePassword();
    } else {
      showErrorMsgToast( t('message.invalidCodeLbl') as string);
    }
  } catch (error) {
    console.log(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  } finally {
    showLoadingIndicator.value = false;
  }
};

const sendTFACode = async () => {
  try {
    showLoadingIndicator.value = false;
    await userModule.sendTFACode();
    showSuccessMsgToast(t('message.tfaCodeSentByEmailMsg', {
        1: user.value ? user.value.username : username.value
      }) as string
    );
  } catch (error) {
    console.log(error);
    if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast( t('message.failedProcessMsg') as string);
    }
  } finally {
    showLoadingIndicator.value = false;
  }
};

</script>
<style>
/* Target the q-tab elements */
.menu-tabs .q-tab {
  margin-right: 50px; 
}
</style>
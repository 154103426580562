<template>
  <q-footer elevated class="bg-secondary text-white q-pt-md q-mt-lg">
    <div class="row justify-center full-width">
      <div class="logo" v-if="showPitchYouLogo">
        <a href="https://www.pitchyou.de" target="_blank"
          ><img src="../assets/pitchyou-logo.png" class="img-fluid"
        /></a>
      </div>
      <div v-if="imprintText" class="q-pl-xl q-pr-xl justify-center">
        {{ imprintText }}
      </div>
    </div>

    <div class="row full-width justify-center items-center q-pa-md">
      <div>
        <a class="text-white q-pa-sm" :href="imprintUrl" target="_blank">{{
          $t('message.legalNoticeFLbl')
        }}</a
        ><a class="text-white q-pa-sm" :href="dataPrivacyUrl" target="_blank">{{
          $t('message.privacyPolicyFLbl')
        }}</a
        ><a
          v-show="showTermsOfUse"
          class="text-white q-pa-sm"
          href="https://www.pitchyou.de/nutzungsbedingungen"
          target="_blank"
          >{{ $t('message.termsOfUseFLbl') }}</a
        >
      </div>
      <div
        class="copyright text-center q-pa-md q-mt-xs bg-secondary"
        v-if="showCopyright"
      >
        &copy; {{ currentYear() }} PitchYou GmbH
      </div>
    </div>
  </q-footer>
</template>

<script setup lang="ts">
let name = 'CustomFooter';

const props = defineProps([
  'showTermsOfUse',
  'showPitchYouLogo',
  'showCopyright',
  'imprintUrl',
  'dataPrivacyUrl',
  'imprintText'
]);

let showTermsOfUse: boolean | undefined = props.showTermsOfUse;
let showPitchYouLogo: boolean | undefined = props.showPitchYouLogo;
let showCopyright: boolean | undefined = props.showCopyright;
let imprintUrl: string | undefined = props.imprintUrl;
let dataPrivacyUrl: string | undefined = props.dataPrivacyUrl;
let imprintText: string | undefined = props.imprintText;
function currentYear() {
  return new Date().getFullYear();
}
</script>

<style></style>

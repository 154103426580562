<template>
  <div class="bg-footer-black text-white flex column min-full-height">
    <div class="q-pa-md flex-grow-max">
      <div class="row">
        <div class="col-12 q-mb-xs">
          <div class="q-mb-sm">
            <div class="logo">
              <img src="../assets/pitchyou-logo.png" class="img-fluid" />
            </div>
          </div>
          <p class="footer-description company-info">
            PitchYou GmbH, Campusallee 9 | 51379 Leverkusen
          </p>
        </div>
        <div class="col-12 q-mb-xs">
          <div>
            <div class="text-h6 text-bold q-mb-sm">
              {{ $t('message.aboutUsLbl') }}
            </div>
            <div class="footer-description">
              <p>
                <a
                  class="text-white"
                  href="https://www.pitchyou.de/impressum/"
                  target="_blank"
                  >{{ $t('message.legalNoticeFLbl') }}</a
                >
              </p>
              <p>
                <a
                  class="text-white"
                  href="https://www.pitchyou.de/datenschutz/"
                  target="_blank"
                  >{{ $t('message.privacyPolicyFLbl') }}</a
                >
              </p>
              <p v-show="showTermsOfUse">
                <a
                  class="text-white"
                  href="https://www.pitchyou.de/nutzungsbedingungen/"
                  target="_blank"
                  >{{ $t('message.termsOfUseFLbl') }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright text-center q-py-md">
      &copy; {{ currentYear() }} PitchYou GmbH
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const name = 'BrandDrawer';
const props = defineProps(['showTermsOfUse']);
const showTermsOfUse = ref<boolean | undefined>(props.showTermsOfUse);
function currentYear() {
  return new Date().getFullYear();
}
</script>

<style></style>

// vue-auth-image.js
import axios from 'axios';

const setMediaSrc = (el, binding) => {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const mediaUrl = binding.value;
    axios({
      method: 'get',
      url: mediaUrl,
      responseType: 'arraybuffer'
    })
      .then(resp => {
        const mimeType = resp.headers['content-type'].toLowerCase();
        //const mediaBase64 = Buffer.from(resp.data, 'binary').toString('base64');
        const arrayBufferView = new Uint8Array(resp.data);
        const binaryString = arrayBufferView.reduce(
          (acc, byte) => acc + String.fromCharCode(byte),
          ''
        );

        const mediaBase64 = btoa(binaryString);

        if (el.tagName === 'IMG') {
          el.src = `data:${mimeType};base64,${mediaBase64}`;
        } else if (el.tagName === 'VIDEO') {
          el.setAttribute('src', `data:${mimeType};base64,${mediaBase64}`);
          el.load();
        } else if (el.tagName === 'AUDIO') {
          el.setAttribute('src', `data:${mimeType};base64,${mediaBase64}`);
          el.load();
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
};

export const authMedia = {
  mounted(el, binding) {
    setMediaSrc(el, binding);
  },
  updated(el, binding) {
    setMediaSrc(el, binding);
  }
};

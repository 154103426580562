import store from '@/store';
import { Action, Module, VuexModule, getModule } from 'vuex-module-decorators';
import {
  bookmarkSite,
  createCandidateTag,
  deleteCandidateTagName,
  doLogAction,
  doStatusTransition,
  fetchAlternativeGroups,
  fetchInterviewRecruiterComments,
  fetchInterviewRecruiterDetail,
  getActionProtocol,
  getCandidateTags,
  getFinalizeAtDate,
  getInterviewAttachments,
  getRecentApplications,
  getTenantGroups,
  loadtemplateCandidate,
  loadtemplates,
  saveInterviewRecruiterComment,
  updateCandidateGroup,
  updateCandidateName,
  updateCandidateTagName,
  updateCandidateTags,
  updateInterviewParams,
  updateInterviewSites
} from '../api';
import {
  ActionLogRequest,
  CandidateTagCreateRequest,
  CandidateTagsUpdateRequest,
  CommentRequest,
  CommentResponse,
  GroupTransitionRequest,
  InterviewActionProtocolResponse,
  InterviewAttachmentResponse,
  InterviewParamsDto,
  LoadInterviewDetailRequest,
  LoadTemplatesRequest,
  RecruiterInterview,
  TagMangementDto,
  TransitionStatusRequest,
  UpdateCandidateName
} from '../models';
import { deleteActionProtocolCommentServer } from '../server/api-server';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'interviewRecruiterModule',
  store
})
class InterviewRecruiterModule extends VuexModule {
  @Action({ rawError: true })
  public async loadInterviewDetail(requestObj: LoadInterviewDetailRequest) {
    try {
      const interview: RecruiterInterview = await fetchInterviewRecruiterDetail(
        requestObj
      );
      return interview;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async loadInterviewComments(interviewGuid: string) {
    try {
      const response: CommentResponse = await fetchInterviewRecruiterComments(
        interviewGuid
      );
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async saveInterviewComment(requestObj: CommentRequest) {
    try {
      const response: any = await saveInterviewRecruiterComment(requestObj);
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async processStatusTransition(requestObj: TransitionStatusRequest) {
    try {
      const interview: RecruiterInterview = await doStatusTransition(
        requestObj
      );
      return interview;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async processActionLog(requestObj: ActionLogRequest) {
    try {
      await doLogAction(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async getActionProtocolForInterview(requestObj: string) {
    try {
      const response: InterviewActionProtocolResponse = await getActionProtocol(
        requestObj
      );
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async getAttachmentsForInterview(requestObj: string) {
    try {
      const response: InterviewAttachmentResponse = await getInterviewAttachments(
        requestObj
      );
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doBookmarkSite(requestObj: string) {
    try {
      await bookmarkSite(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetRecentApplications(requestObj: string) {
    try {
      return await getRecentApplications(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateCandidateName(requestObj: UpdateCandidateName) {
    try {
      return await updateCandidateName(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doFetchAlternativeGroups(interviewGuid: string) {
    try {
      return await fetchAlternativeGroups(interviewGuid);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateCandidateGroup(requestObj: GroupTransitionRequest) {
    try {
      return await updateCandidateGroup(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }
  @Action({ rawError: true })
  public async dogetCandidateTags() {
    try {
      return await getCandidateTags();
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateCandidateTags(requestObj: CandidateTagsUpdateRequest) {
    try {
      return await updateCandidateTags(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doCreateCandidateTag(request: CandidateTagCreateRequest) {
    try {
      return await createCandidateTag(request);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateInterviewParams(requestObj: InterviewParamsDto) {
    try {
      return await updateInterviewParams(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateCandidateTagName(requestObj: TagMangementDto) {
    try {
      return await updateCandidateTagName(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doDeleteCandidateTagName(requestObj: TagMangementDto) {
    try {
      return await deleteCandidateTagName(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetTenantGroups() {
    try {
      return await getTenantGroups();
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doLoadTemplates(requestObj: LoadTemplatesRequest) {
    try {
      return await loadtemplates(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doLoadTemplateCandidate(requestObj: LoadTemplatesRequest) {
    try {
      return await loadtemplateCandidate(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doDeleteActionProtocolComment(commentSlug: string) {
    try {
      return await deleteActionProtocolCommentServer(commentSlug);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateInterviewSites(requestObj: string) {
    try {
      return await updateInterviewSites(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetFinalizeAtDate(interviewGuid: string) {
    try {
      return await getFinalizeAtDate(interviewGuid);
    } catch (error) {
      throw new Error(error as string);
    }
  }
}

export default getModule(InterviewRecruiterModule);

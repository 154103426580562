<template>
  <div></div>
</template>
<script setup lang="ts">
import { ref, onMounted as vueOnMounted} from 'vue';
import tenantModule from '@/store/modules/tenantModule';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { NotificationMessage } from '../store/models';

const onMounted = vueOnMounted;

const socket = ref<any>(null);
const stompClient = ref<any>(null);
const subscription = ref<any>(null);

const emit = defineEmits(['on-notification']);

onMounted(async () => {
  const tenant = tenantModule.tenantInfo || null;
  if (!tenant) {
    await tenantModule.loadTenantInfo();
  }
  if (tenantModule.tenantInfo) {
    connect(tenantModule.tenantInfo.identifier);
  }
});

const connect = (identifier: string) => {
  try {
    socket.value = new SockJS('/notification');

    stompClient.value = Stomp.over(socket.value, {
        debug: false,
        protocols: ['v12.stomp']
      });

    stompClient.value.reconnect_delay = 10000;

    stompClient.value.connect(
        {},
        (frame: any) => {
          subscription.value = stompClient.value.subscribe(
            '/notifychannel/' + identifier + '-notification',
            (tick: any) => {
              onMessageReceived(tick);
            }
          );
        },
        (error: any) => {
          console.log('error while connecting to socket');
        }
      );
  } catch (err) {
    console.log(err);
  }
};

const cleanUp = () => {
  try {
    if (subscription.value) {
      subscription.value.unsubscribe();
      disconnect();
      socket.value = null;
    }
  } catch (err) {
    console.log(err);
  }
};


  const disconnect = () => {
  if (stompClient.value) {
    stompClient.value.disconnect();
  }
};

 const onMessageReceived = (payload: any): any => {
  if (payload && payload.body) {
    sendMessage('Received payload inform server for testing');
    emit('on-notification', payload.body);
  }
  return {};
};

  const sendMessage = (msg: string) => {
  if (tenantModule.tenantInfo) {
    const queueId = tenantModule.tenantInfo.identifier + '-notification';
    const sendMsgObj = {
      queueId: queueId,
      message: msg,
      userId: 'recruiter',
      id: '' + new Date().getTime()
    };
    stompClient.value.send(
      '/app/pitchyou/' + queueId + '/message',
      JSON.stringify(sendMsgObj),
      {}
    );
  }
};

</script>

<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <q-footer elevated class="bg-secondary text-white q-pt-md q-mt-lg">
    <div class="row full-width justify-center items-center q-pa-md">
      <div v-if="imprintUrl">
        <a class="text-white q-pa-sm" :href="imprintUrl" target="_blank">
          {{ $t('message.legalNoticeFLbl') }}
        </a>
      </div>
    </div>
  </q-footer>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const { imprintUrl } = defineProps(['imprintUrl']);
</script>

<style></style>

<template>
  <q-layout view="hhh lpr fff" class="shadow-3">
    <q-page-container> <router-view /> </q-page-container
  ></q-layout>
</template>

<script setup lang="ts">
import { Vue, Component } from 'vue-property-decorator';

import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();

const name = ref('BlankLayout');

</script>

<style></style>

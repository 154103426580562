import store from '@/store';
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';
import {
  chatMessageUploadMedia,
  doConfirmReadChatMessage,
  doGetAllChatMessages,
  doGetAllChatMessagesCount,
  doGetChatMessages,
  doSendChatMessage,
  doSendTemplateChatMessage,
  getMessage,
  getMessageCenterContact,
  setChatTranslation,
  updateMessagesRun
} from '../api';
import {
  AllChatMessageRequestDto,
  ChatMessageRequest,
  SendChatMessageRequest,
  SetChatTranslationRequest
} from '../models';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'chatMessageModule',
  store
})
class ChatMessageModule extends VuexModule {
  @Action({ rawError: true })
  public async getChatMessages(requestObj: ChatMessageRequest) {
    try {
      const chatMessages = await doGetChatMessages(requestObj);
      return chatMessages;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async getAllChatMessages(request: AllChatMessageRequestDto) {
    try {
      const chatMessages = await doGetAllChatMessages(request);
      return chatMessages;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async getAllChatMessagesCount() {
    try {
      const chatMessages = await doGetAllChatMessagesCount();
      return chatMessages;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async confirmReadChatMessage(requestObj: ChatMessageRequest) {
    try {
      await doConfirmReadChatMessage(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async sendChatMessage(requestObj: SendChatMessageRequest) {
    try {
      const newSlug = await doSendChatMessage(requestObj);
      return newSlug;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async sendTemplateChatMessage(requestObj: SendChatMessageRequest) {
    try {
      const chatMessage = await doSendTemplateChatMessage(requestObj);
      return chatMessage;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async sendMediaChatMessage(requestObj: object) {
    try {
      const chatMessage = await chatMessageUploadMedia(requestObj);
      return chatMessage;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doSetChatTranslation(requestObj: SetChatTranslationRequest) {
    try {
      await setChatTranslation(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetMessageCenterContact(interviewGuid: string) {
    try {
      return await getMessageCenterContact(interviewGuid);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetMessage(slug: string) {
    try {
      return await getMessage(slug);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateMessages(request: string[]) {
    try {
      return await updateMessagesRun(request);
    } catch (error) {
      throw new Error(error as string);
    }
  }
}

export default getModule(ChatMessageModule);

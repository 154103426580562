<template>
  <q-layout view="hhh lpr fff" class="shadow-3">
    <q-header elevated class="text-white bg-primary">
      <div class="hf-container flex column justify-between">
        <div class="q-py-xs flex justify-around items-center">
          <div style="height: 65px; max-height:65px; max-width: 200px;">
            <img
              :src="tenantLite.logo"
              style="height:100%;"
              class="rounded-borders shadow-4"
              v-if="tenantLite && tenantLite.logo && '' != tenantLite.logo"
            />
            <TenantInfo :tenantLite="tenantLite" v-else />
          </div>
        </div>
      </div>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
    <CustomFooter
      v-if="showCustomFooter"
      :showTermsOfUse="showTermsOfUse"
      :showPitchYouLogo="showPitchYouLogo"
      :showCopyright="showCopyright"
      :imprintUrl="imprintUrl"
      :dataPrivacyUrl="dataPrivacyUrl"
      :imprintText="imprintText"
    />
    <BrandFooter :showTermsOfUse="true" v-else />
  </q-layout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import TenantInfo from '@/components/TenantInfo.vue';
import BrandFooter from '@/components/BrandFooter.vue';
import CustomFooter from '@/components/CustomFooter.vue';
import { TenantLite } from '../store/models';
import tenantModule from '@/store/modules/tenantModule';


import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { colors, setCssVar, useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();


const name = 'CorporateIdentityLayout';
const tenantLite = ref<TenantLite | null>(null);
const showCustomFooter = ref(false);
const showTermsOfUse = ref(false);
const showPitchYouLogo = ref(false);
const showCopyright = ref(false);
const imprintUrl = ref<string | null>(null);
const dataPrivacyUrl = ref<string | null>(null);
const imprintText = ref<string | null>(null);

  onMounted(async () => {
    loadTenantInfoLite();
  });

  async function loadTenantInfoLite() {
  try {
    tenantLite.value = tenantModule.tenantInfoLite || null;
    if (!tenantLite.value) {
      await tenantModule.loadTenantInfoLite();
      tenantLite.value = tenantModule.tenantInfoLite;
    }
    if (tenantLite.value && tenantLite.value.primaryColor) {
      setCssVar('primary', tenantLite.value.primaryColor);
    }
    if (tenantLite.value && tenantLite.value.secondaryColor) {
      setCssVar('secondary', tenantLite.value.secondaryColor);
    }

    if (tenantLite.value) {
      showCustomFooter.value = tenantLite.value.customFooter;
      showTermsOfUse.value = tenantLite.value.showPitchYouTermsOfUse;
      showPitchYouLogo.value = tenantLite.value.showPitchYouLogo;
      showCopyright.value = tenantLite.value.showCopyright;
      imprintUrl.value = tenantLite.value.imprintUrl || null;
      dataPrivacyUrl.value = tenantLite.value.dataPrivacyUrl || null;
      imprintText.value = tenantLite.value.imprintText || null;
    }
  } catch (error) {
    console.error(error);
  }
  if (!tenantLite.value) {
    router.push({ name: 'invalidtenant' });
  }
}


</script>

<style>
.logoImage {
  height: 65px;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border: 1px;
  border-radius: 10px;
}
</style>

<template>
  <div :class="appliedDivStyleClass" v-if="tenantName">
    <span>{{ tenantName }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Tenant, TenantLite, TenantLoginInfo } from '../store/models';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tenantModule from '@/store/modules/tenantModule';

const props = defineProps(['tenant', 'tenantLoginInfo', 'tenantInfoLite', 'divStyleClass']);
const tenantName = ref('');

onMounted(() => {
  const tenant = props.tenant || props.tenantLoginInfo || props.tenantInfoLite;
  tenantName.value = tenant ? tenant.name || '' : '';
});

const appliedDivStyleClass = ref('');

const getAppliedDivStyleClass = () => {
  if (props.divStyleClass) {
    appliedDivStyleClass.value = props.divStyleClass;
  } else {
    appliedDivStyleClass.value = 'text-bold text-subtitle1';
  }
};

getAppliedDivStyleClass();
</script>

<style scoped></style>
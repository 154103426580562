import store from '@/store';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';
import {
  deactivateTenant,
  fetchTenantInfo,
  fetchTenantInfoLite,
  fetchTenantLoginInfo,
  fetchTenantMessageInfo,
  isSuperAdminATSLoginAllowed,
  wipeOutTenant
} from '../api';
import {
  DeactivateTenantDto,
  Tenant,
  TenantLite,
  TenantLoginInfo,
  WipeOutAuthCredentialsDto
} from '../models';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tenantModule',
  store
})
class TenantModule extends VuexModule {
  public tenantInfo: Tenant | null = null;
  public isSuperAdminATSLoginAllowed = false;
  public tenantInfoLite: TenantLite | null = null;
  public tenantLoginInfo: TenantLoginInfo | null = null;

  @Mutation
  public setTenantInfo(tenantInfo: Tenant) {
    this.tenantInfo = tenantInfo;
  }

  @Mutation
  public setSuperAdminATSLogin(loginAllowed: boolean) {
    this.isSuperAdminATSLoginAllowed = loginAllowed;
  }

  @Action({ commit: 'setTenantInfo' })
  public resetTenantInfo() {
    return null;
  }

  @Mutation
  public setTenantInfoLite(tenantInfoLite: TenantLite) {
    this.tenantInfoLite = tenantInfoLite;
  }

  @Action({ commit: 'setTenantInfoLite' })
  public resetTenantInfoLite() {
    return null;
  }

  @Mutation
  public setTenantLoginInfo(tenantLoginInfo: TenantLoginInfo) {
    this.tenantLoginInfo = tenantLoginInfo;
  }

  @Action({ commit: 'setTenantLoginInfo' })
  public resetTenantLoginInfo() {
    return null;
  }

  @Action({ commit: 'setTenantInfo', rawError: true })
  public async loadTenantInfo() {
    try {
      if (this.tenantInfo) {
        return this.tenantInfo;
      }
      const tenant = await fetchTenantInfo();
      return tenant;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ commit: 'setTenantInfoLite', rawError: true })
  public async loadTenantInfoLite(languageCode?: string) {
    try {
      if (this.tenantInfoLite) {
        return this.tenantInfoLite;
      }
      const tenant = await fetchTenantInfoLite(languageCode);
      return tenant;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ commit: 'setTenantLoginInfo', rawError: true })
  public async loadTenantLoginInfo() {
    try {
      if (this.tenantLoginInfo) {
        return this.tenantLoginInfo;
      }
      const tenantLoginInfo = await fetchTenantLoginInfo();
      return tenantLoginInfo;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async loadTenantMessageInfo() {
    try {
      const tenantMessageInfo = await fetchTenantMessageInfo();
      return tenantMessageInfo;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doWipeOutTenant(requestObj: WipeOutAuthCredentialsDto) {
    try {
      return wipeOutTenant(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doDeactivateTenant(requestObj: DeactivateTenantDto) {
    try {
      return deactivateTenant(requestObj);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doCheckSuperAdminATSLoginAllowed(guid: string) {
    try {
      return isSuperAdminATSLoginAllowed(guid);
    } catch (error) {
      throw new Error(error as string);
    }
  }
}

export default getModule(TenantModule);

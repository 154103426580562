<template>
  <q-layout view="hhh lpr fff" class="shadow-3">
    <q-page-container>
      <router-view />
    </q-page-container>
    <Footer :imprintUrl="imprintUrl" />
  </q-layout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import Footer from '@/components/Footer.vue';
import { Tenant } from '../store/models';
import tenantModule from '@/store/modules/tenantModule';

import {  ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';


const route = useRoute();
const router = useRouter();
const tenant = ref<Tenant | null>(null);
const imprintUrl = ref<string | null>(null);

  onMounted(async () => {
    loadTenantInfo();
  });

 async function loadTenantInfo() {
  try {
        tenant.value = tenantModule.tenantInfo || null;
        if (!tenant.value) {
          await tenantModule.loadTenantInfo();
          tenant.value = tenantModule.tenantInfo;
          imprintUrl.value = tenant.value?.imprintUrl || null;
        }

        if (route.query.imprint) {
          imprintUrl.value = route.query.imprint as string;
        }
      } catch (error) {
        console.error(error);
      }

      if (!tenant.value) {
        router.push({ name: 'invalidtenant' });
      }
    }
</script>

<style></style>

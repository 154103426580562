<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App'
});
</script>

<style>
.platform-ios textarea,
.platform-ios input[type] {
  font-size: 16px !important;
}
</style>

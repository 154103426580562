import script from "./LoadingIndicator.vue?vue&type=script&setup=true&lang=ts"
export * from "./LoadingIndicator.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerFacebook from 'quasar/src/components/spinner/QSpinnerFacebook.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QSpinnerFacebook});

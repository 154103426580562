<template>
  <q-card style="min-width: 30vw; max-width: 80vw;">
    <q-card-section class="row items-center">
      <div class="text-h6">
        {{ $t('message.manageTagsLbl') }}
      </div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-separator />
    <q-card-section>
      <q-input
        dense
        filled
        color="primary"
        v-model="tagsFilterSearch"
        :label="$t('message.reportsFilterSearchLbl')"
        @update:model-value="onTagsFilterSearchChange"
        ><template v-slot:append>
          <q-icon
            name="close"
            @click="
              tagsFilterSearch = '';
              onTagsFilterSearchChange();
            "
            class="cursor-pointer"
          />
        </template> </q-input
    ></q-card-section>
    <q-card-section style="max-height: 40vh; min-height: 40vh" class="scroll">
      <q-list>
        <q-item v-for="tag in candidateTagOptions" :key="tag.value">
          <q-item-section>
            <div class="row full-width">
              <q-input
                v-model="tag.label"
                dense
                outlined
                style="min-width: 30vw;"
                @update:model-value="tagValueChanged(tag)"
              />
              <q-btn
                icon="save"
                color="primary"
                class="q-mr-md"
                flat
                :disabled="tag.disableSave"
                @click="updateTagName(tag)"
                ><q-tooltip>{{
                  $t('message.updateContactLbl')
                }}</q-tooltip></q-btn
              >
              <q-btn
                icon="delete"
                color="primary"
                class="q-mr-md"
                flat
                @click="
                  showTagDeleteConfirmation = true;
                  selectedTagToDelete = tag;
                "
                ><q-tooltip>{{ $t('message.deleteLbl') }}</q-tooltip></q-btn
              >
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
    <q-separator />
    <div class="row row full-width">
      <div class="row q-pa-md" v-show="showAddTag">
        <q-input
          v-model="newTagValue"
          dense
          outlined
          label="New Tag"
          style="min-width: 25vw;"
        />
        <q-btn
          v-show="isAdmin()"
          icon="group_add"
          color="primary"
          flat
          @click="showAddGroupsDialog = true"
          ><q-tooltip>{{ $t('message.addGroupTooltip') }}</q-tooltip></q-btn
        >
        <q-btn
          icon="save"
          color="primary"
          flat
          :disabled="disableSave()"
          @click="createNewTag"
          ><q-tooltip>{{ $t('message.saveLbl') }}</q-tooltip></q-btn
        >
        <q-btn
          icon="cancel"
          color="primary"
          flat
          @click="
            showAddTag = false;
            disableAddTag = false;
            newTagValue = '';
          "
          ><q-tooltip>{{ $t('message.cancelLbl') }}</q-tooltip></q-btn
        >
      </div>
      <q-space />
      <q-card-actions align="right" class="q-pa-md">
        <q-btn
          icon="add"
          :label="$t('message.addCandidateTagButtonLbl')"
          color="primary"
          :disabled="disableAddTag"
          @click="
            showAddTag = true;
            disableAddTag = true;
          "
        />
      </q-card-actions>
    </div>
    <LoadingIndicator :showLoading="showLoadingIndicator"></LoadingIndicator>
    <q-dialog v-model="showTagDeleteConfirmation" persistent>
      <q-card style="min-width: 40vw">
        <q-card-section class="row  items-center">
          <q-avatar icon="delete_forever" color="negative" text-color="white" />
          <span class="q-ml-sm">{{
            $t('message.tagDeleteConfirmationMsg')
          }}</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            :label="$t('message.deleteConfirmationNoBtnLbl')"
            color="primary"
            v-close-popup
          />
          <q-btn
            flat
            :label="$t('message.deleteConfirmationYesBtnLbl')"
            color="primary"
            @click.prevent="deleteTagName"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showAddGroupsDialog" persistent>
      <q-card style="min-width: 40vw; max-width: 80vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">
            {{ $t('message.addGroupTooltip') }}
          </div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-separator />
        <q-card-section style="max-height: 40vh" class="scroll">
          <q-input
            class="q-pb-lg"
            dense
            filled
            color="primary"
            v-model="groupFiltersearch"
            :label="$t('message.reportsFilterSearchLbl')"
            @update:model-value="onGroupFilterSearchChange"
            ><template v-slot:append>
              <q-icon
                name="close"
                @click="
                  groupFiltersearch = '';
                  onGroupFilterSearchChange();
                "
                class="cursor-pointer"
              />
            </template>
          </q-input>
          <q-list>
            <q-item v-for="group in userGroups" :key="group.id" clickable>
              <q-item-section>
                <div
                  class="row full-width items-center"
                  @click.prevent="onClickUserGroup(group)"
                >
                  <div>
                    <q-checkbox
                      v-model="group.selected"
                      @update:model-value="selectUserGroup(group)"
                    />
                  </div>
                  <div>
                    <q-item-label>{{ group.itemName }}</q-item-label>
                  </div>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-separator />
      </q-card>
    </q-dialog>
  </q-card>
</template>
<script setup lang="ts">
import { showErrorMsgToast, showSuccessMsgToast } from '@/common';
import {
  CandidateTagCreateRequest,
  FilterResponseDto,
  ILabelValue,
  TagMangementDto,
  User
} from '@/store/models';
import interviewRecruiterModule from '@/store/modules/interviewRecruiterModule';
import userModule from '@/store/modules/userModule';
import LoadingIndicator from './LoadingIndicator.vue';

import {  ref,  defineProps, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();

const loadTags = defineProps(['loadTags']);

const candidateTagOptions = ref<TagMangementDto[]>([]);
const filteredTagOptions = ref<TagMangementDto[]>([]);
const tagsFilterSearch = ref<string>('');
const showAddTag = ref<boolean>(false);
const disableAddTag = ref<boolean>(false);
const newTagValue = ref<string>('');
const showLoadingIndicator = ref<boolean>(false);
const showTagDeleteConfirmation = ref<boolean>(false);
const selectedTagToDelete = ref<TagMangementDto | null>(null);
const showAddGroupsDialog = ref<boolean>(false);
const groupFiltersearch = ref<string>('');
const userGroups = ref<FilterResponseDto[]>([]);
const filteredGroups = ref<FilterResponseDto[]>([]);
const selectedGroupSlug = ref<number | null>(null);
const loggedInUser = ref<User | null>(null);

const emit = defineEmits(['refresh-candidate-tags']);

 // Watchers using the watch function
 // eslint-disable-next-line @typescript-eslint/no-empty-function
 watch(loadTags, (newValue, oldValue) => {
    loadCandidateTags();
  }, { immediate: true });
    
 async function loadCandidateTags() {
    try {
      loggedInUser.value = userModule.loggedInUser;
      candidateTagOptions.value = [];
      let candidateTags = await interviewRecruiterModule.dogetCandidateTags();
      candidateTags.forEach(e => {
        let candidateTag: TagMangementDto = {
          disableSave: true,
          label: e.label,
          value: e.value
        };
        candidateTagOptions.value.push(candidateTag);
      });
      filteredTagOptions.value = candidateTagOptions.value;
      if (isAdmin()) {
        await getTenantGroups();
      }
    } catch (error) {
      console.log(error);
      if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
        router.push({ name: 'home' });
      } else {
        showErrorMsgToast( t('message.failedProcessMsg') as string);
      }
    }
  }

  const onTagsFilterSearchChange = () => {
    candidateTagOptions.value = filteredTagOptions.value.filter(x =>
      x.label.toLowerCase().match(tagsFilterSearch.value.toLowerCase())
    );
  };

  const tagValueChanged = (tag: TagMangementDto) => {
    candidateTagOptions.value.forEach(e => {
      if (e.value === tag.value) {
        e.disableSave = false;
      }
    });
  };

  const  createNewTag = async () => {
    try {
      showLoadingIndicator.value = true;
      let newTagDto: CandidateTagCreateRequest = {
        newTagName: newTagValue.value
      };
      if (isAdmin() && selectedGroupSlug.value != null) {
        newTagDto.groupSlug = selectedGroupSlug.value;
      }
      await interviewRecruiterModule.doCreateCandidateTag(newTagDto);
      await loadCandidateTags();
      showAddTag.value = false;
      disableAddTag.value  = false;
      selectedGroupSlug.value  = null;
      showLoadingIndicator.value  = false;
      emit('refresh-candidate-tags', {});
      showSuccessMsgToast(
        
        t('message.newTagCeatedMsgToast') as string
      );
    } catch (error) {
      showLoadingIndicator.value  = false;
      console.log(error);
      if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
        router.push({ name: 'home' });
      } else {
        showErrorMsgToast( t('message.failedProcessMsg') as string);
      }
    }
  };

  const  updateTagName = async (tag: TagMangementDto) => {
    try {
      await interviewRecruiterModule.doUpdateCandidateTagName(tag);
      candidateTagOptions.value.forEach(e => {
        if (e.value === tag.value) {
          e.disableSave = true;
        }
      });
      emit('refresh-candidate-tags', {});
      showSuccessMsgToast( t('message.changesSavedText') as string);
    } catch (error) {
      showLoadingIndicator.value = false;
      console.log(error);
      if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
        router.push({ name: 'home' });
      } else {
        showErrorMsgToast( t('message.failedProcessMsg') as string);
      }
    }
  };

  const  deleteTagName = async () => {
    try {
      if (selectedTagToDelete.value) {
        await interviewRecruiterModule.doDeleteCandidateTagName(
          selectedTagToDelete.value
        );
        await loadCandidateTags();
        selectedTagToDelete.value = null;
        showTagDeleteConfirmation.value = false;
        emit('refresh-candidate-tags', {});
        showSuccessMsgToast(
          
          t('message.tagDeletedMsgToast') as string
        );
      }
    } catch (error) {
      showLoadingIndicator.value = false;
      console.log(error);
      if ((error as any)?.message && (error as any).message.includes('TOKEN_ERROR')) {
        router.push({ name: 'home' });
      } else {
        showErrorMsgToast( t('message.failedProcessMsg') as string);
      }
    }
  };

  const  getTenantGroups = async () => {
    const response = await interviewRecruiterModule.doGetTenantGroups();
    userGroups.value = response;
    filteredGroups.value = userGroups.value;
  };

  const onGroupFilterSearchChange = () => {
    userGroups.value = filteredGroups.value.filter(x =>
      x.itemName.toLowerCase().match(groupFiltersearch.value.toLowerCase())
    );
  };

  const onClickUserGroup = (group: FilterResponseDto) => {
    group.selected = !group.selected;
    selectUserGroup(group);
  };

  const selectUserGroup = (group: FilterResponseDto) => {
   selectedGroupSlug.value = group.id;
    if (group.selected) {
      userGroups.value.forEach((element, index) => {
        if (element.id != group.id) {
          element.selected = false;
        }
      });
    } else {
      selectedGroupSlug.value = null;
    }
    showAddGroupsDialog.value = false;
  };

  const isAdmin = () => {
    if (loggedInUser.value != null) {
      if (loggedInUser.value.roleLevel.toString() === 'SUPERADMIN') {
        return true;
      } else if (loggedInUser.value.roleLevel.toString() === 'ADMIN') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const disableSave = () => {
    if (isAdmin()) {
      return newTagValue.value.length < 2 || selectedGroupSlug.value == null;
    } else {
      return newTagValue.value.length < 2;
    }
  };

</script>

<template>
  <q-layout view="hhh lpr fff" class="shadow-3">
    <q-header
      class="bg-primary text-white content-center justify-center items-center row q-mb-xl"
    >
    </q-header>
    <q-page-container>
      <q-page class="bg-primary q-pt-xl"> <router-view /></q-page>
    </q-page-container>
    <BrandFooter :showTermsOfUse="false" />
  </q-layout>
</template>

<script setup lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import BrandFooter from '@/components/BrandFooter.vue';

import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();


const name= ref('ExternalLayout');

</script>

<style></style>

<template>
  <q-layout view="hhh lpr fff" class="bg-grey-3">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { Vue, Component } from 'vue-property-decorator';
import TenantInfo from '@/components/TenantInfo.vue';
import { Tenant } from '../store/models';
import tenantModule from '@/store/modules/tenantModule';

import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();

const name = 'SmallLayout';
const tenant = ref<Tenant | null>(null);

  onMounted(async () => {
    loadTenantInfo();
  });

 async function loadTenantInfo() {
    try {
      tenant.value = tenantModule.tenantInfo || null;
      if (!tenant.value) {
        await tenantModule.loadTenantInfo();
        tenant.value = tenantModule.tenantInfo;
      }
    } catch (error) {
      console.log(error);
    }
    if (!tenant.value) {
      router.push({ name: 'invalidtenant' });
    }
  }

</script>

<style scoped></style>

import { app } from './main';

export function showErrorMsgToast(msg: string): void {
  app.config.globalProperties.$q.notify({
    color: 'pink-7',
    icon: 'warning',
    position: 'top',
    message: msg
  });
}

export function showSuccessMsgToast(msg: string): void {
  app.config.globalProperties.$q.notify({
    color: 'green',
    icon: 'done',
    position: 'top',
    message: msg
  });
}
